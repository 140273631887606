export default class ImgPlayer {
  constructor(options) {
    this.fps = options.fps
    this.reverse = options.reverse
    this.selector = options.selectorType + options.selector
    this.nb = options.files.length
    this.currentImage = 0
    this.interval = null
    this.reversing = false
  }

  reset () {
    this.switchImage(0)
  }
  clear () {
    clearInterval(this.interval)
  }
  play () {
    this.interval = setInterval(() => {
      let next
      if (this.reversing) {
        next = this.currentImage - 1
        if (next < 0) {
          next = 1
          this.reversing = false
        }
      } else {
        next = this.currentImage + 1
        if (next === this.nb) {
          if (this.reverse) {
            next = this.currentImage - 1
            this.reversing = true
          } else {
            next = 0
          }
        }
      }
      this.switchImage(next)
    }, 1 / this.fps * 1000)
  }
  pause () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
  switchImage (next) {
    document.querySelectorAll(this.selector + '_' + this.currentImage).forEach(img => {
      img.classList.remove('img-player__image--active')
    })
    this.currentImage = next
    document.querySelectorAll(this.selector + '_' + next).forEach(img => {
      img.classList.add('img-player__image--active')
    })
  }
}