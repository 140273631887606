export default [
  'doggy_0.png',
  'doggy_1.png',
  'doggy_2.png',
  'doggy_3.png',
  'doggy_4.png',
  'doggy_5.png',
  'doggy_6.png',
  'doggy_7.png',
  'doggy_8.png',
  'doggy_9.png',
  'doggy_10.png',
  'doggy_11.png',
  'doggy_12.png',
  'doggy_13.png',
  'doggy_14.png',
  'doggy_15.png',
  'doggy_16.png',
  'doggy_17.png',
]