<template>
  <div class="theme-picker" :style="{maxHeight: pickerHeight}">
    <div class="theme-picker__list">
      <div v-for="(theme, i) in filteredThemes"
           class="theme-picker__scheme"
           @click="() => {handleClick(theme.val)}"
           :key="i">
        <div class="theme-picker__color" :style="{background: theme.black, transition: 'all .3s ease ' + (i * .05 + .15) + 's'}"></div>
        <div class="theme-picker__color" :style="{background: theme.secondary, transition: 'all .3s ease ' + (i * .05 + .1) + 's'}"></div>
        <div class="theme-picker__color" :style="{background: theme.primary, transition: 'all .3s ease ' + (i * .05 + .05) + 's'}"></div>
        <div class="theme-picker__color" :style="{background: theme.white, transition: 'all .3s ease ' + (i * .05) + 's'}"></div>
      </div>
      <Leprechaun></Leprechaun>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import Leprechaun from './Leprechaun'

  export default {
    components: {
      Leprechaun
    },
    data () {
      return {
        minDelay: .4,
        changeTimer: null,
        waveTimer: null
      }
    },
    computed: {
      ...mapState('themePicker', {
        currentTheme: state => state.current,
        forcedTheme: state => state.forced,
        themes: state => state.themes
      }),
      filteredThemes () {
        const arr = []
        for (let i = 0; i < this.themes.length; i++) {
          let val = this.currentTheme + i
          if (val >= this.themes.length) {
            val -= this.themes.length
          }
          arr.push({
            primary: this.themes[val].primary,
            secondary: this.themes[val].secondary,
            white: this.themes[val].white,
            black: this.themes[val].black,
            val: val
          })
        }
        return arr
      },
      pickerHeight () {
        return (this.themes.length * 3 + .5) + 'rem'
      }
    },
    beforeMount () {
      document.addEventListener('wheel', this.handleWheel)
    },
    beforeUnmount () {
      document.removeEventListener('wheel', this.handleWheel)
    },
    methods: {
      ...mapMutations('themePicker', {
        changeTheme: 'change',
        forceTheme: 'force'
      }),
      ...mapMutations({
        removeClass: 'removeClass'
      }),
      ...mapActions({
        playClass: 'playClass'
      }),
      handleWheel (e) {
        if (!this.changeTimer) {
          let theme
          if (e.deltaY > 0) {
            theme = this.currentTheme - 1

            if (theme < 0) {
              theme = this.themes.length - 1
            }
          } else {
            theme = this.currentTheme + 1

            if (theme === this.themes.length) {
              theme = 0
            }
          }
          this.initChange(theme)
        }
      },
      handleClick (theme) {
        if (!this.changeTimer) {
          this.initChange(theme)
        }
      },
      initChange (theme) {
        this.changeTheme(theme)
        this.changeTimer = setTimeout(() => {
          this.changeTimer = null
        }, this.minDelay * 1000)
      },
      applyTheme (theme) {
        const root = document.documentElement;
        root.style.setProperty('--white', theme.white)
        root.style.setProperty('--primary', theme.primary)
        root.style.setProperty('--secondary', theme.secondary)
        root.style.setProperty('--black', theme.black)

        this.playClass({
          value: 'changing-theme',
          duration: this.minDelay
        })
      }
    },
    watch: {
      currentTheme () {
        this.applyTheme(this.themes[this.currentTheme])
        if (this.forcedTheme !== null) {
          this.forceTheme(null)
        }
      },
      forcedTheme () {
        if (this.forcedTheme === null) {
          this.removeClass('konami')
        }
        this.applyTheme(this.forcedTheme || this.themes[this.currentTheme])
      }
    }
  }
</script>