import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      keysHeld: [],
      keysSequence: [],
      sequenceTimer: null
    }
  },
  computed: {
    ...mapState('keys', {
      held: state => state.held,
      lastTapped: state => state.lastTapped,
      sequence: state => state.sequence,
      tappedDuration: state => state.tappedDuration,
      sequenceCleared: state => state.sequenceCleared,
      resetKeys: state => state.resetKeys,
      bannedKeys: state => state.bannedKeys
    })
  },
  created () {
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount () {
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  watch: {
    keysHeld: {
      deep: true,
      handler () {
        if (this.keysHeld !== this.held) {
          this.registerHold(this.keysHeld)
        }
      }
    },
    keysSequence: {
      deep: true,
      handler () {
        if (this.keysSequence !== this.sequence) {
          this.registerSequence(this.keysSequence)
        }
      }
    },
    sequenceCleared () {
      if (this.sequenceCleared) {
        this.keysSequence = []
        clearTimeout(this.sequenceTimer)
        this.sequenceTimer = null
        this.sequenceDoneCleared()
      }
    }
  },
  methods: {
    ...mapMutations('keys', [ 'registerHold', 'registerTap', 'registerSequence', 'sequenceDoneCleared' ]),
    handleKeyUp (e) {
      if (this.bannedKeys.includes(e.key)) {
        e.preventDefault()
      }
      if (this.keysHeld.indexOf(e.key) >= 0) {
        this.keysHeld.splice(this.keysHeld.indexOf(e.key), 1)
      }
    },
    handleKeyDown (e) {
      if (this.bannedKeys.includes(e.key)) {
        e.preventDefault()
      }
      this.resetKeys.forEach(key => {
        if (e.key === key && this.keysSequence[0] !== key) {
          this.keysSequence = []
        }
      })
      if (this.keysHeld.indexOf(e.key) < 0) {
        this.keysHeld.push(e.key)
        this.keysSequence.push(e.key)
        this.registerTap(e.key)
      }
      if (this.sequenceTimer) {
        clearTimeout(this.sequenceTimer)
      }
      this.sequenceTimer = setTimeout(() => {
        this.registerTap(null)
        this.keysSequence = []
        this.sequenceTimer = null
      }, this.tappedDuration * 1000)
    }
  }
}