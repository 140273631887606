<template>
  <div>
    <transition name="fade">
      <div v-if="doggyActive || (!doggyActive && !minTimeReached)" :style="{transition: displayTransition}">
        <div v-for="(doggy, i) in doggies" class="img-player" :style="doggy" :key="i">
          <div class="img-player__holder" :style="images.heightRatio">
            <img v-for="(image, j) in images.files" class="img-player__image" :class="images.selector + '_' + j" :src="images.directory + image" :key="j"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import doggySequence from '../data/doggy_sequence'
  import ImgPlayer from '../classes/img-player'

  export default {
    data () {
      return {
        letters: ['d', 'o', 'g'],
        d: false,
        o: false,
        g: false,
        images: {
          reverse: true,
          fps: 15,
          directory: '/images/doggy_sequence/',
          files: doggySequence,
          heightRatio: {
            paddingBottom: '162.5%'
          },
          selector: 'doggy',
          selectorType: '.'
        },
        doggies: [
          {
            bottom: '0',
            width: '300px',
            left: 'calc(50% - 150px)'
          },
          {
            bottom: '0',
            width: '200px',
            left: 'calc(75% - 100px)'
          },
          {
            bottom: '0',
            width: '200px',
            left: 'calc(25% - 100px)'
          }
        ],
        player: null,
        displayDelay: 0.5,
        doggyTimer: null,
        playTime: null,
        minPlaytime: 3,
        minTimeReached: true,
        audio: null
      }
    },
    computed: {
      ...mapState('keys', {
        held: state => state.held
      }),
      ...mapState({
        dog: state => state.eggs.dog
      }),
      doggyActive () {
        return this.letters.every(letter => this[letter])
      },
      displayTransition () {
        return 'all ' + this.displayDelay + 's ease'
      }
    },
    created () {
      this.unwatch = this.$store.watch(state => state.keys.held, (keys) => {
        this.letters.forEach(letter => {
          this[letter] = keys.includes(letter)
        })
      })
      this.player = new ImgPlayer(this.images)
      this.audio = this.$aPlayer.register({
        file: 'doggy_song.mp3',
        resetOnPause: true,
        fade: .5,
        loop: true
      })
    },
    mounted () {
      this.player.reset()
    },
    beforeUnmount () {
      this.unwatch()
    },
    watch: {
      doggyActive () {
        if (this.doggyTimer) {
          clearTimeout(this.doggyTimer)
          this.doggyTimer = null
        }
        if (this.doggyActive) {
          this.player.clear()
          this.player.play()
          this.$aPlayer.play(this.audio)
          if (!this.playTime) {
            this.minTimeReached = false
            this.playTime = setTimeout(() => {
              this.minTimeReached = true
            }, this.minPlaytime * 1000)
          }
          this.addClass('doggo')
        } else if (this.minTimeReached) {
          this.endDoggies()
        }
      },
      minTimeReached () {
        if (!this.doggyActive && !this.doggyTimer) {
          this.endDoggies()
        }
      }
    },
    methods: {
      ...mapMutations({
        addClass: 'addClass',
        removeClass: 'removeClass'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      }),
      endDoggies () {
        this.$aPlayer.pause(this.audio)
        this.doggyTimer = setTimeout(() => {
          this.player.pause()
          this.player.reset()
          clearTimeout(this.playTime)
          this.playTime = null
          this.minTimeReached = true
          if (!this.dog.found) {
            this.findEgg('dog')
          }
          this.removeClass('doggo')
        }, this.displayDelay * 1000)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .img-player {
    position: fixed;
  }
</style>