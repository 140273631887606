<template>
  <div class="console" :class="{'console--opened': opened}" @click="focus">
    <div class="console__chat">
      <div v-for="line in lines" class="console__text" :key="line.id">
        <p data-space="mr1">{{ line.from }} :</p>
        <p v-html="line.text" :data-font="line.font"></p>
        <transition name="tpin">
          <img v-if="!ballFound && line.ball" @click="() => {findBall(5)}" class="ball console__ball" src="../assets/images/ball_5.svg" />
        </transition>
      </div>
    </div>
    <div class="console__bottom">
      <span class="console__text" data-font="nowrap">SAY :</span>
      <input ref="say" class="console__input" v-model="thinking" @keydown="say" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import rocket from '../data/rocket'

  export default {
    data () {
      return {
        robot: 'DefinitelyARealPerson',
        eggDelay: 1,
        answerDelay: .5,
        waitingAnswer: false,
        answerTimer: null,
        needsName: true,
        thinking: '',
        name: 'stranger',
        aPlusCount: -1,
        aCount: -1,
        gCount: -1,
        lines: [],
        wrongTests: [
          {
            check: (v) => v.replace(/\s/g, '').length === 0,
            text: () => `Sorry, you said something ?`
          },
          {
            check: (v) => v.match(/\s/g) ? v.match(/\s/g).length > 1 : false,
            text: () => `Huh ? You seriously thought I could understand sentences ?`
          },
          {
            check: (v) => v.length > 0,
            text: (v) => {
              this.aCount++
              this.aCount = this.aCount === this.wrongAnswers.length ? 0 : this.aCount
              let a = this.wrongAnswers[this.aCount](v)
              if (this.aCount === this.wrongAnswers.length - 1) {
                this.aPlusCount++
                if (this.aPlusCount > 2) {
                  this.aPlusCount = 0
                  a = `Duuuuude, did you not realize my stock of answers is already out ?<br>Can you start thinking before talking ?`
                }
              }
              return a
            }
          },
        ],
        wrongAnswers: [
          (v) => `I have absolutely no idea what ${v} means, if you want help just say it !`,
          () => `Wait, did you think I was a real AI ? ROFL`,
          (v) => `Bro... What is ${v} ??? I literally understand 7 different words !`
        ],
        gimmeAnswers: [
          (v) => `Soooo you want a ${v} ? I don't even know what it is !`,
          (v) => `Sure, sure, a ${v} ! Nope, I don't have that, never had it.`,
          () => `Nooooope !`,
        ],
        nameTemplate: ['The name you\'ve given ', ' and '],
        nameTests: [
          {
            test: (v) => v.match(/\s/g) ? v.match(/\s/g).length > 1 : false,
            sanitize: (v) => v.split(' ')[0],
            part: 'isn\'t a name, that\'s what I call a sentence',
            end: '. We\'ll just take the first word, okay ?'
          },
          {
            test: (v) => v.length >= 20,
            sanitize: (v) => v.slice(0, 20),
            part: 'is waaaaaaaaaaaay too long',
            end: '. I\'m gonna trim that quickly.'
          },
          {
            test: (v) => /[0-9]/.test(v),
            sanitize: (v) => v.replace(/[0-9]/g, ''),
            part: 'contains numbers',
            end: '. Are you sure you\'re not a robot ?'
          },
          {
            test: (v) => /[!@#$%^&*)(+=._\]\\²></['"{}~`°-]/.test(v),
            sanitize: (v) => v.replace(/[!@#$%^&*)(+=._\]\\²></['"{}~`°-]/g, ''),
            part: 'contains special characters',
            end: '. Are you sure you\'re not a robot ?'
          }
        ],
        names: ['Spot', 'Buddy', 'Bandit', 'Fido', 'Brownie', 'Rex', 'Rufus'],
        known: [
          {
            check: (val) => val === 'help',
            text: (v) => {
              return {
                answer: `I can give you ${v} !<br>I can also give you something else if you start with "gimme"`
              }
            }
          },
          {
            check: (val) => val === 'rocketman',
            text: () => {
              if (!this.jetEgg.found) {
                setTimeout(() => {
                  this.findEgg('jetpack')
                  this.lines.push({
                    id: Date.now(),
                    from: this.robot,
                    text: 'What did you make me do now ?',
                    font: ''
                  })
                }, this.eggDelay * 1000)
              }
              return {
                answer: rocket.ascii,
                font: 'small'
              }
            }
          },
          {
            check: (val) => val === 'gimme instrument',
            text: () => {
              let a = 'I already gave you my SIMON, stop being greedy !'
              if (!this.foundInstrument) {
                a = `Hey, I DO have that in stock. Not that I have anything else though.<br>Or... do I ? I'm not sure anymore.<br>You'll find it near your Sidebar&reg;.`
                this.giveInstrument()
              }
              return {
                answer: a
              }
            }
          },
          {
            check: (val) => val === 'gimme balls',
            text: () => {
              return {
                answer: `You're going to make me blush... ＠＾▽＾＠`
              }
            }
          },
          {
            check: (val) => val === 'gimme ball' || val === 'gimme dragonball' || val === 'gimme dragon ball',
            text: () => {

              return {
                answer: this.ballFound ? 'I already gave it to you, didn\'t I ?' : `You mean this ? `,
                ball: this.ballFound ? null : true
              }
            }
          },
        ]
      }
    },
    computed: {
      ...mapState({
        opened: state => state.consoleOpened,
        jetEgg: state => state.eggs.jetpack,
        consoleEgg: state => state.eggs.console,
        balls: state => state.balls,
        foundInstrument: state => state.foundInstrument,
        firstName: state => state.firstName
      }),
      ballFound () {
        return this.balls[5]
      }
    },
    created () {
      document.addEventListener('keydown', this.toggleConsole)
      this.updateName()
    },
    beforeDestroy () {
      document.removeEventListener('keydown', this.toggleConsole)
    },
    watch: {
      opened () {
        if (this.opened) {
          this.focus()
          if (this.lines.length === 0) {
            const msg = this.needsName ? `Oh hi Mark ! Wait... you aren't Mark, what's your name ?` : `Nice to see you again ${this.name} ! What do you want today ?`
            this.lines.push({
              id: 0,
              from: 'DefinitelyARealPerson',
              text: msg
            })
          }
        }
      },
      firstName () {
        this.updateName()
      }
    },
    methods: {
      ...mapMutations({
        toggle: 'handleConsole',
        giveInstrument: 'findInstrument',
        findBall: 'findBall',
        setName: 'setName'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      }),
      updateName () {
        if (this.firstName) {
          this.name = this.firstName
          this.needsName = false
        } else {
          this.name = 'stranger'
          this.needsName = true
          this.lines = []
        }
      },
      toggleConsole (e) {
        if (e.key === '`' || e.key === '²') {
          e.preventDefault()
          this.toggle(null)
          if (!this.consoleEgg.found) {
            this.findEgg('console')
          }
        }
      },
      say (e) {
        if (e.key === 'Enter' && !this.waitingAnswer) {
          this.waitingAnswer = true
          this.lines.push({
            id: Date.now(),
            from: this.name,
            text: this.thinking,
            font: ''
          })
          const answer = String(this.thinking)
          this.answerTimer = setTimeout(() => {
            if (this.needsName) {
              this.giveName(answer)
            } else {
              this.answer(answer)
            }
            this.waitingAnswer = false
          }, this.answerDelay * 1000)
          this.thinking = ''
        }
      },
      answer (val) {
        let answered
        this.known.forEach(k => {
          if (k.check(val.toLowerCase())) {
            const t = k.text(val)
            this.lines.push({
              id: Date.now(),
              from: this.robot,
              text: t.answer,
              font: t.font || null,
              ball: t.ball || null
            })
            answered = true
          }
        })
        if (!answered) {
          if (val.slice(0, 5) === 'gimme') {
            if (val.replace(/\s/g, '') === 'gimme') {
              this.lines.push({
                id: Date.now(),
                from: this.robot,
                text: `Ehm, I'm okay with giving you something, but you gotta say what you want.`,
                font: ''
              })
            } else {
              this.gCount++
              this.gCount = this.gCount === this.gimmeAnswers.length ? 0 : this.gCount
              this.lines.push({
                id: Date.now(),
                from: this.robot,
                text: this.gimmeAnswers[this.gCount](val.slice(6)),
                font: ''
              })
            }
          } else {
            for (let i = 0; i < this.wrongTests.length; i++) {
              if (this.wrongTests[i].check(val)) {
                this.lines.push({
                  id: Date.now(),
                  from: this.robot,
                  text: this.wrongTests[i].text(val),
                  font: ''
                })
                break
              }
            }
          }
        }
      },
      giveName (val) {
        if (val.toLowerCase() === 'mark') {
          this.lines.push({
            id: Date.now() + 1,
            from: this.robot,
            text: `So you WERE Mark in the end.<br>You gotta tell me, did you actually hit her ?<br>Anyway I'm glad to meet you, I'm ${this.robot}.`,
            font: ''
          })
          this.name = val
          this.needsName = false
          this.setName(this.name)
          return
        }
        let n = val
        let score = 0
        let parts = []
        let end = ''
        this.nameTests.forEach(t => {
          if (t.test(val)) {
            score++
            parts.push(t.part)
            end = t.end
            n = t.sanitize(n)
          }
        })
        score = n.length < 3 ? 4 : score
        let answer
        switch (score) {
          case 0:
            this.name = val
            answer = 'Yay thanks ! You managed to give a (proper ?) name !<br>Hi ' + this.name + '.'
            break
          case 1:
            this.name = n
            answer = this.nameTemplate[0] + parts[0] + end + '<br>I\'ll call you ' + this.name + '.'
            break
          case 2:
            this.name = n
            answer = this.nameTemplate[0] + parts[0] + this.nameTemplate[1] + parts[1] + end + '<br>I\'ll call you ' + this.name + '.'
            break
          default:
            this.name = this.names[Math.floor(Math.random() * this.names.length)]
            answer = 'Ooooh boy, you\'re doing this on purpose aren\'t you ?<br>I\'m definitely not going to bother, I\'ll call you ' + this.name + '.'
        }
        this.lines.push({
          id: Date.now(),
          from: this.robot,
          text: `${answer}<br>Glad to meet you, I'm ${this.robot}.`,
          font: ''
        })
        this.setName(this.name)
        this.needsName = false
      },
      focus () {
        this.$refs.say.focus()
      },
      includesArr (arr, val) {
        return arr.some(() => val.includes(arr))
      }
    }
  }
</script>