<template>
  <transition name="fade">
    <div v-if="foundInstrument" class="simon__container">
      <div class="simon__flipper" :class="{'simon__flipper--flipped': simonFlipped}">
        <svg class="simon" :class="{'simon--active': simonActive}" @click="simonActive = true" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200">
          <circle class="simon__body" cx="100" cy="100" r="98.75"/>
          <g class="simon__logo">
            <path class="simon__sh simon__sh--white" d="M121.632,96.76v9.476h3.877c0,0,0-6.526,0-7.13c0-0.604,1.487-0.836,2.022-0.836 c0.534,0,1.788,0.232,1.788,0.859c0,0.626,0,7.106,0,7.106h3.901c0,0,0-7.409,0-7.942c0-0.535-1.068-1.533-3.901-1.533 c-2.834,0-3.811,0.906-3.811,0.906V96.76H121.632z"/>
            <path class="simon__sh simon__sh--white" d="M114.036,96.76c-1.264,0-5.791-0.169-5.791,1.866s0,5.002,0,5.869c0,0.867,1.595,1.741,2.833,1.741 c1.239,0,2.958,0,2.958,0s1.72,0,2.958,0c1.24,0,2.834-0.874,2.834-1.741c0-0.866,0-3.833,0-5.869S115.3,96.76,114.036,96.76z  M115.879,103.975c0,0.489-1.007,0.846-1.836,0.846h-0.015c-0.828,0-1.834-0.356-1.834-0.846c0-0.487,0-4.032,0-4.729 c0-0.697,0.96-0.898,1.842-0.898c0.883,0,1.843,0.201,1.843,0.898C115.879,99.942,115.879,103.487,115.879,103.975z"/>
            <path class="simon__sh simon__sh--white" d="M102.365,96.76c-1.709,0-2.742,0.33-3.293,0.592c-0.261,0.123-0.583,0.345-0.743,0.459 c-0.444-0.501-1.573-1.051-3.674-1.051c-2.833,0-3.81,0.906-3.81,0.906V96.76h-3.878v9.476h3.878c0,0,0-6.526,0-7.13 c0-0.604,1.487-0.836,2.021-0.836c0.535,0,1.789,0.232,1.789,0.859c0,0.626,0,7.106,0,7.106h3.902c0,0,0-5.08,0-7.13 c0-0.604,1.486-0.836,2.021-0.836c0.534,0,1.788,0.232,1.788,0.859c0,0.626,0,7.106,0,7.106h3.901c0,0,0-7.409,0-7.942 C106.267,97.758,105.199,96.76,102.365,96.76z"/>
            <rect class="simon__sh simon__sh--white" x="80.963" y="93.775" width="4.134" height="1.556"/>
            <rect class="simon__sh simon__sh--white" x="81.09" y="96.76" width="3.879" height="9.476"/>
            <path class="simon__sh simon__sh--white" d="M66.779,101.846c0,0,0,2.193,0,2.896s2.378,1.493,3.918,1.493c1.539,0,3.523,0,4.645,0 c1.122,0,3.918-0.57,3.918-1.493c0-0.924,0-2.609,0-3.314c0-0.705-0.264-1.316-1.704-1.61c-1.44-0.295-6.859-0.951-6.859-1.625 c0-0.675,0-1.4,0-1.967c0-0.565,0.783-0.867,1.146-0.867c0.362,0,1.928,0,2.415,0c0.487,0,1.084,0.55,1.084,1.068 c0,0.518,0,1.766,0,1.766h3.918c0,0,0-2.296,0-2.834s-2.421-1.594-3.918-1.594c-1.499,0-3.763,0-4.645,0 c-0.883,0-3.918,0.577-3.918,1.594c0,1.019,0,2.783,0,3.468c0,0.686,0.794,1.359,2.327,1.615c1.533,0.256,6.236,0.835,6.236,1.405 c0,0.57,0,1.848,0,2.206c0,0.361-0.929,0.69-1.417,0.69s-1.708,0-2.23,0s-0.999-0.503-0.999-0.863c0-0.361,0-2.033,0-2.033H66.779z "/>
          </g>
          <g>
            <path class="simon__sh simon__sh--green-dark" d="M93.081,58.358c-17.77,2.932-31.792,16.953-34.722,34.723l-0.01,0.006l-47.626,2.962 c2.011-46.213,39.114-83.316,85.327-85.327l-2.962,47.626L93.081,58.358z"/>
            <g class="simon__key" :class="{'simon__key--active': notes.e.held}">
              <path class="simon__sh simon__sh--clear simon__sh--green" d="M93.081,53.358c-17.77,2.932-31.792,16.953-34.722,34.723l-0.01,0.006l-47.626,2.962 C12.733,44.836,49.837,7.733,96.05,5.723l-2.962,47.626L93.081,53.358z"/>
              <path class="simon__sh simon__sh--green-dark" d="M51.017,51.251h8.292v2.737H47.464V42.783h11.685v2.738h-8.131v1.584h7.491v2.337h-7.491V51.251z"/>
            </g>
          </g>
          <g>
            <path class="simon__sh simon__sh--red-dark" d="M106.919,58.358c17.771,2.932,31.792,16.953,34.723,34.723l0.01,0.006l47.626,2.962 c-2.011-46.213-39.114-83.316-85.327-85.327l2.962,47.626L106.919,58.358z"/>
            <g class="simon__key" :class="{'simon__key--active': notes.r.held}">
              <path class="simon__sh simon__sh--clear simon__sh--red" d="M106.919,53.358c17.771,2.932,31.792,16.953,34.723,34.723l0.01,0.006l47.626,2.962 c-2.011-46.213-39.114-83.316-85.327-85.327l2.962,47.626L106.919,53.358z"/>
              <path class="simon__sh simon__sh--red-dark" d="M153.305,53.988h-4.051l-2.561-3.602h-3.217v3.602h-3.554V42.784h9.987c2.049,0,2.914,0.864,2.914,2.913 v1.521c0,1.872-0.721,2.721-2.369,2.896L153.305,53.988z M143.477,45.329v2.593h4.93c0.656,0,0.864-0.208,0.864-0.864v-0.864 c0-0.656-0.208-0.864-0.864-0.864H143.477z"/>
            </g>
          </g>
          <g>
            <path class="simon__sh simon__sh--blue-dark" d="M106.919,141.642c17.771-2.932,31.792-16.953,34.723-34.724l0.01-0.006l47.626-2.962 c-2.011,46.214-39.114,83.316-85.327,85.327l2.962-47.627L106.919,141.642z"/>
            <g class="simon__key" :class="{'simon__key--active': notes.t.held}">
              <path class="simon__sh simon__sh--clear simon__sh--blue" d="M106.919,136.642c17.771-2.932,31.792-16.953,34.723-34.724l0.01-0.006l47.626-2.962 c-2.011,46.214-39.114,83.316-85.327,85.327l2.962-47.627L106.919,136.642z"/>
              <path class="simon__sh simon__sh--blue-dark" d="M152.969,136.012v3.057h-4.578v8.148h-3.554v-8.148h-4.578v-3.057H152.969z"/>
            </g>
          </g>
          <g>
            <path class="simon__sh simon__sh--yellow-dark" d="M93.081,141.642c-17.77-2.932-31.792-16.953-34.722-34.724l-0.01-0.006l-47.626-2.962 c2.011,46.214,39.114,83.316,85.327,85.327l-2.962-47.627L93.081,141.642z"/>
            <g class="simon__key" :class="{'simon__key--active': notes.y.held}">
              <path class="simon__sh simon__sh--clear simon__sh--yellow" d="M93.081,136.642c-17.77-2.932-31.792-16.953-34.722-34.724l-0.01-0.006L10.723,98.95 c2.011,46.214,39.114,83.316,85.327,85.327l-2.962-47.627L93.081,136.642z"/>
              <path class="simon__sh simon__sh--yellow-dark" d="M60.653,136.012l-5.49,7.139v4.065h-3.554v-4.065l-5.49-7.139h4.306l3.058,4.273l3.057-4.273H60.653z"/>
            </g>
          </g>
        </svg>
        <i v-if="!ballFound" @click="simonFlipped = true" class="icon-reset simon__hidden"></i>
        <transition name="tpin">
          <img v-if="!ballFound" @click="this.unflip" class="ball simon__ball" src="../assets/images/ball_7.svg"/>
        </transition>
      </div>
      <i class="icon-cross simon__close" :class="{'simon__close--active': simonActive}" @click="simonActive = false"></i>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    data () {
      return {
        simonActive: false,
        simonFlipped: false,
        noteList: ['e', 'r', 't', 'y'],
        notes: {
          e: {
            id: null,
            value: 'mimaj.mp3',
            held: false
          },
          r: {
            id: null,
            value: 'domaj.mp3',
            held: false
          },
          t: {
            id: null,
            value: 'solmaj.mp3',
            held: false
          },
          y: {
            id: null,
            value: 'sol.mp3',
            held: false
          }
        },
        target: [
          'e',
          'e',
          'e',
          'r',
          'e',
          't',
          'y'
        ]
      }
    },
    computed: {
      ...mapState({
        mario: state => state.eggs.mario,
        foundInstrument: state => state.foundInstrument,
        ballFound: state => state.balls[7]
      }),
      ...mapState('keys', {
        sequence: state => state.sequence
      })
    },
    methods: {
      ...mapMutations('keys', {
        setResetKey: 'setResetKey',
        pushKey: 'pushKey',
        clearSequence: 'clearSequence'
      }),
      ...mapMutations({
        findBall: 'findBall'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      }),
      press (key) {
        if (this.simonActive && this.notes[key] && !this.notes[key].held) {
          this.$aPlayer.play(this.notes[key].id)
          this.notes[key].held = true
        }
      },
      release (key) {
        if (this.simonActive && this.notes[key]) {
          this.notes[key].held = false
        }
      },
      unflip () {
        this.simonFlipped = false
        this.findBall(7)
      }
    },
    created () {
      this.setResetKey('e')
      document.addEventListener('keydown', e => {
        this.press(e.key)
      })
      document.addEventListener('keyup', e => {
        this.release(e.key)
      })

      Object.keys(this.notes).forEach(note => {
        this.notes[note].id = this.$aPlayer.register({
          file: this.notes[note].value,
          resetOnPause: true,
          resetOnPlay: true
        })
      })
    },
    watch: {
      sequence () {
        if (this.target[0] === this.sequence[0]) {
          for (let i = 0; i < this.sequence.length; i++) {
            if (this.sequence[i] !== this.target[i]) {
              this.clearSequence()
              return
            }
          }
          if (this.target.length === this.sequence.length) {
            if (!this.mario.found) {
              this.findEgg('mario')
            }
          }
        }
      }
    }
  }
</script>