export default {
  registerHold (state, keys) {
    const arr = []
    keys.forEach(key => {
      arr.push(key)
    })
    state.held = arr
  },
  registerTap (state, key) {
    state.lastTapped = key
  },
  registerSequence (state, sequence) {
    const arr = []
    sequence.forEach(key => {
      arr.push(key)
    })
    state.sequence = arr
  },
  clearSequence (state) {
    state.sequence = []
    state.sequenceCleared = true
  },
  sequenceDoneCleared (state) {
    state.sequenceCleared = false
  },
  setResetKey (state, val) {
    if (!state.resetKeys.includes(val)) {
      state.resetKeys.push(val)
    }
  }
}