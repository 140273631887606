export default {
  change (state, theme) {
    state.current = theme
  },
  create (state, theme) {
    state.themes.push(theme)
  },
  force (state, val) {
    state.forced = val
  }
}