<template>
  <div class="sidebar"
       :class="{'sidebar--opened': opened}"
       @wheel="e => {e.stopPropagation()}">
    <button class="sidebar__btn"
            @click="() => {toggle(null)}">
      <i class="icon-list"></i>
    </button>
    <div class="sidebar__outer">
      <div class="sidebar__inner">
        <p class="sidebar__title"
           data-font="huge primary semi"
           data-space="pb5">Sidebar&reg;</p>
        <div data-flex="column">
          <div v-for="egg in eggs"
               class="sidebar__item"
               :class="{'sidebar__item--notfound': !egg.found}"
               :style="[{order: egg.index}, opened ? {transition: 'all .4s ease ' + (egg.index * 0.035) + 's'} : {}]"
               data-space="mb4"
               @click="() => {clickHint(egg.isHiddenEgg)}"
               :key="egg.index">
            <div data-flex>
              <i v-if="egg.found && egg.icon" :class="egg.icon" class="sidebar__icon" data-font="primary huge"></i>
              <i v-else class="icon-egg sidebar__icon" data-font="primary huge"></i>
              <div data-space="ml2"
                   data-col="grow">
                <p class="sidebar__hint">{{ egg.title }}</p>
                <p v-if="egg.hasHtml && !egg.found" data-font="medium white short" data-space="mt1" v-html="egg.hint"></p>
                <p v-else data-font="medium white short" data-space="mt1">
                  {{ egg.found ? egg.successAlt || egg.success : typeof egg.hint === 'string' ? egg.hint : egg.hint[visits - 1] }}
                  <span v-if="egg.title === 'Invoke Shenron' && ballsFound < 7">{{ ` ${7 - ballsFound} more to go !` }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapState({
        eggs: state => state.eggs,
        opened: state => state.sidebarOpened,
        visits: state => state.visits,
        balls: state => state.balls,
        dragonEgg: state => state.eggs.shenron
      }),
      ballsFound () {
        return Object.keys(this.balls).filter(ball => this.balls[ball] === true).map(ball => ball).length
      }
    },
    methods: {
      ...mapMutations({
        toggle: 'handleSidebar'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      }),
      clickHint (val) {
        if (val && !this.eggs.here.found) {
          this.findEgg('here')
        }
      }
    },
    watch: {
      ballsFound () {
        if (Object.keys(this.balls).every(ball => this.balls[ball] === true) && !this.dragonEgg.found) {
          this.findEgg('shenron')
        }
      }
    }
  }
</script>
