<template>
  <div class="portfolio" @wheel="e => {e.stopPropagation()}">
    <div class="portfolio__outer">
      <div class="container container--small">
        <div class="container__inner">
          <div class="portfolio__inner">
            <p data-font="huge semi fit primary" data-space="px5">My projects <span data-font="base">(some of them)</span></p>
            <div v-for="project in projects" class="portfolio__item project" :class="{'project--wip': project.wip}" data-space="px5 pt5" :key="project.id">
              <a :class="{'project__link': project.link}" :href="project.link" target="_blank">
                <img class="project__image" :src="project.image"/>
                <p class="project__title">{{ project.title }}</p>
              </a>
              <p data-font="medium regular white" data-space="px3 pt3" v-html="project.description"></p>
              <p data-font="medium regular white" data-space="px3 pt2" v-html="project.job"></p>
              <p data-flex="items-center" data-font="pre medium regular" data-space="px3 pt2">
                <span data-font="bold primary">Techs/libs used : </span>
                <span v-for="(tech, i) in project.techs" class="project__tech" :key="`${project.id}_${i}`">{{ tech }}</span>
                <transition name="tpin">
                  <img v-if="project.ball && !ballFound" @click="() => {findBall(4)}" class="ball project__ball" src="../assets/images/ball_4.svg"/>
                </transition>
              </p>
            </div>
          </div>
        </div>
        <i class="icon-cross portfolio__close" @click="() => {handlePortfolio(false)}"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    data () {
      return {
        projects: [
          {
            id: 0,
            title: 'delupay pro',
            image: '/images/delupay.png',
            description: `DeluPay Pro, allows businesses to accept secure, low-cost payments via QR code, NFC, and SMS without needing extra hardware.<br>It streamlines transaction management, supports refunds, and integrates easily with e-commerce platforms like Magento and WooCommerce.`,
            job: `I took over Delupay pro's frontend in the early stages of development, making it evolve, building most of its core features up to a fully functional app.`,
            link: 'https://app.delupay.com/',
            techs: ['Vue.js 3', 'Typescript', 'Tailwind.css/Windi.css', 'Pinia', 'Rest']
          },
          {
            id: 1,
            title: 'mj-fleet manager app',
            image: '/images/mj-fleet.png',
            description: `MJ-Fleet is a customizable SaaS tool designed to streamline field operations and intervention management for businesses. It offers features like real-time task scheduling, GPS tracking, and reporting to enhance productivity and simplify work for technicians and coordinators in industries such as maintenance, logistics, and healthcare`,
            job: `I worked on the manager app, maintaining code, building new features or revamping older ones,<br>along with a team of up to 5 other developers.`,
            link: 'https://mj-fleet.com/fonctionnalites/operateurs-bureau/',
            techs: ['Vue.js 2', 'Typescript', 'Tailwind.css', 'Vuex', 'Rest']
          },
          {
            id: 2,
            title: 'evarinvest-am.com',
            image: '/images/evarinvest.jpg',
            description: `The goal of this project was to make a smoother, more original experience for an asset management company's website.`,
            job: `I've done this app's development, integration, and created the animated background.
            <br>This website's design is by <span data-font="bold primary">Sophie Laplace</span>.`,
            link: 'http://www.evarinvest-am.com/',
            techs: ['Vue.js 2', 'Javascript', 'SCSS', 'Gsap']
          },
          {
            id: 3,
            title: 'mistywhale.com',
            image: '/images/mistywhale.jpg',
            description: `A tiny one-page website made in preparation of the Protoverse's game launch.`,
            job: `I've designed and developed this project from A to Z, except for logos and images which are from the game itself.`,
            link: 'https://mistywhale.com/',
            techs: ['Nuxt.js', 'Javascript', 'SCSS', 'Gsap']
          },
          {
            id: 4,
            title: 'docs.seriousfactory.com',
            image: '/images/doc-sf.jpg',
            description: `This is a documentation generator made for serious-factory's softwares.
            <br>It is made so that people with no experience in development can create, write, manage and deploy documentations, while making sure the visual identity is respected.`,
            job: `I fully made this website's back-office for documentations management, and integrated/developed the front-end application for viewing them.`,
            link: 'https://docs.seriousfactory.com/',
            techs: ['Vue.js 2', 'Javascript', 'SCSS', 'Symfony', 'Twig'],
          },
          {
            id: 5,
            title: 'elpince.dev',
            image: '/images/elpince.jpg',
            description: `No need to tell you that this project is the website you're currently browsing (but I just did).`,
            job: `I designed, thought and implemented 100% of this project (except for some icons/images/sounds), I hope you're liking it !`,
            link: null,
            techs: ['Vue.js 2', 'Javascript', 'SCSS', 'Gsap'],
            ball: true
          },
        ]
      }
    },
    created () {
      document.addEventListener('keyup', this.escape)
    },
    beforeDestroy () {
      document.removeEventListener('keyup', this.escape)
    },
    computed: {
      ...mapState({
        ballFound: state => state.balls[4],
        portfolioOpened: state => state.portfolioOpened
      }),
    },
    methods: {
      ...mapMutations({
        handlePortfolio: 'handlePortfolio',
        findBall: 'findBall'
      }),
      escape (e) {
        if (this.portfolioOpened && e.key === 'Escape') {
          this.handlePortfolio(false)
        }
      }
    }
  }
</script>
