export default {
  addClass (state, val) {
    if (!state.appClasses.includes(val)) {
      state.appClasses.push(val)
    }
  },
  removeClass (state, val) {
    if (state.appClasses.includes(val)) {
      state.appClasses.splice(state.appClasses.indexOf(val), 1)
    }
  },
  handleSidebar (state, val) {
    if (val !== null) {
      state.sidebarOpened = val
    } else {
      state.sidebarOpened = !state.sidebarOpened
    }
  },
  handleConsole
    (state, val) {
    if (val !== null) {
      state.consoleOpened = val
    } else {
      state.consoleOpened = !state.consoleOpened
    }
  },
  handlePortfolio (state, val) {
    if (val !== null) {
      state.portfolioOpened = val
    } else {
      state.portfolioOpened = !state.portfolioOpened
    }
  },
  validateEgg (state, val) {
    state.foundEgg.push(val)
  },
  validateReward (state, val) {
    if (state.eggs[val] && state.eggs[val].found === false) {
      state.eggs[val].found = true
    }
    state.foundEgg.shift()
  },
  findInstrument (state) {
    state.foundInstrument = true
  },
  clearInstrument (state) {
    state.foundInstrument = false
  },
  findBall (state, val) {
    state.balls[val] = true
  },
  retrieveEggs (state, arr) {
    arr.forEach(el => {
      state.eggs[el].found = true
    })
  },
  retrieveBalls (state, arr) {
    arr.forEach(el => {
      state.balls[el] = true
    })
  },
  clearBalls (state) {
    Object.keys(state.balls).forEach(ball => {
      state.balls[ball] = false
    })
  },
  clearEggs (state) {
    Object.keys(state.eggs).forEach(el => {
      state.eggs[el].found = false
    })
  },
  queueValidation (state, val) {
    if (state.needsValidation === null) {
      state.needsValidation = val
    }
  },
  clearValidation (state) {
    state.needsValidation = null
  },
  setVisits (state, val) {
    state.visits = val
  },
  setName (state, val) {
    state.firstName = val
  },
  goToMenu (state, val) {
    state.plusMenu = !(state.menuItem >= val)
    state.menuItem = state.menuItem === val ? null : val
  }
}
