import { gsap } from 'gsap'

export default class AudioPlayer {
  constructor (opts) {
    this.path = opts.path
    this.volume = opts.volume || 1
    this.audios = []
    this.muted = false
    this.container = null
  }
  init () {
    this.container = document.createElement('div')
    document.querySelector('body').appendChild(this.container)
    return this
  }
  register (audio) {
    const id = this.audios.length
    this.audios[id] = new Audio(audio, this.container, this.path, this.volume).init()
    return id
  }
  play (id) {
    this.audios[id].play()
  }
  pause (id) {
    this.audios[id].pause()
  }
  changeVolume (volume) {
    this.volume = volume
    this.audios.forEach(audio => {
      audio.setVolume(volume)
    })
  }
  mute (val) {
    this.muted = val
    if (val) {
      this.audios.forEach(audio => {
        audio.setVolume(0)
      })
    } else {
      this.audios.forEach(audio => {
        audio.setVolume(this.volume)
      })
    }
  }
}

class Audio {
  constructor (audio, container, path, volume) {
    this.path = path + audio.file
    this.resetOnPause = audio.resetOnPause || false
    this.resetOnPlay = audio.resetOnPlay || false
    this.fade = audio.fade || 0
    this.loop = audio.loop || false
    this.container = container
    this.maxVolume = volume
    this.el = null
    this.fading = null
  }
  init() {
    this.el = document.createElement('audio')
    if (this.loop) {
      this.el.setAttribute('loop', true)
    }
    this.el.setAttribute('src', this.path)
    this.container.appendChild(this.el)

    return this
  }
  setVolume (volume) {
    this.el.volume = volume
    this.maxVolume = volume
  }
  play () {
    this.el.volume = this.el.volume <= this.maxVolume ? this.el.volume : this.maxVolume
    if (!this.el.paused) {
      if (this.resetOnPlay) {
        this.el.currentTime = 0
      }
      if (this.fading) {
        this.fading.kill()
        this.fading = gsap.to(this.el, {
          duration: this.fade,
          ease: 'sine.out',
          volume: this.maxVolume,
          onComplete: () => {
            this.fading = null
          }
        })
      }
    }
    if (this.el.paused) {
      if (this.fade > 0) {
        if (this.fading) {
          this.fading.kill()
        }
        this.fading = gsap.to(this.el, {
          duration: this.fade,
          ease: 'sine.out',
          volume: this.maxVolume,
          onComplete: () => {
            this.fading = null
          }
        })
      }
      this.el.play()
    }
  }
  pause () {
    if (!this.el.paused) {
      if (this.fade > 0) {
        if (this.fading) {
          this.fading.kill()
        }
        this.fading = gsap.to(this.el, {
          duration: this.fade,
          ease: 'sine.out',
          volume: 0,
          onComplete: () => {
            this.el.pause()
            if (this.resetOnPause) {
              this.el.currentTime = 0
            }
            this.fading = null
          }
        })
      } else {
        this.el.pause()
        if (this.resetOnPause) {
          this.el.currentTime = 0
        }
      }
    }
  }
}