<template>
  <div class="code" data-font="huge" data-space="py5 px5">
    <transition-group name="keypress" class="code__list" tag="div">
      <div v-for="key in keys" class="code__item" :key="key.id">
        <div class="code__button">
          <svg class="code__svg" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100">
            <circle class="code__border" :stroke-dashoffset="key.offset" cx="50" cy="50" r="40" />
          </svg>
          <div class="code__key" :class="{'code__key--active': key.value !== null}">
            <i class="code__icon" :class="[key.icon]"></i>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { gsap } from 'gsap'

  export default {
    data () {
      return {
        target: [
          'ArrowUp',
          'ArrowUp',
          'ArrowDown',
          'ArrowDown',
          'ArrowLeft',
          'ArrowRight',
          'ArrowLeft',
          'ArrowRight',
          'b',
          'a'
        ],
        keyMap: {
          ArrowUp: 'icon-uarr',
          ArrowDown: 'icon-darr',
          ArrowLeft: 'icon-larr',
          ArrowRight: 'icon-rarr',
          b: 'icon-bkey',
          a: 'icon-akey',
        },
        keys: [],
        konaDuration: 10,
        skewDuration: 1,
        skewClass: 'skewy--',
        sequenceLength: 0
      }
    },
    created () {
      this.setResetKey('ArrowUp')
    },
    computed: {
      ...mapState('keys', {
        tappedDuration: state => state.tappedDuration,
        sequence: state => state.sequence,
      }),
      ...mapState({
        codeEgg: state => state.eggs.code
      })
    },
    methods: {
      ...mapMutations('keys',  {
        clearSequence: 'clearSequence',
        setResetKey: 'setResetKey'
      }),
      ...mapActions({
        findEgg: 'findEgg',
        playClass: 'playClass'
      }),
      ...mapMutations('themePicker', {
        forceTheme: 'force'
      }),
      ...mapMutations({
        addClass: 'addClass',
        removeClass: 'removeClass'
      })
    },
    watch: {
      sequence () {
        if (this.sequence[0] === this.target[0]) {
          for (let i = 0; i < this.sequence.length; i++) {
            if (this.sequence[i] !== this.target[i]) {
              this.clearSequence()
              this.keys = []
              break
            } else if (!this.keys[i]) {
              this.addClass(this.skewClass + i)
              this.keys.push({
                value: this.sequence[i],
                icon: this.keyMap[this.sequence[i]],
                offset: 0,
                id: Date.now()
              })
            } else if (this.keys[i].value !== this.sequence[i]) {
              this.addClass(this.skewClass + i)
              this.keys[i].value = this.sequence[i]
              this.keys[i].icon = this.keyMap[this.sequence[i]]
              this.keys[i].animation.kill()
              this.keys[i].animation = gsap.to(this.keys[i], {
                duration: .3,
                ease: 'linear',
                offset: 0
              })
            }
          }
          if (this.keys.length < this.target.length) {
            const item = {
              value: null,
              icon: null,
              offset: 0,
              id: Date.now() + 1
            }
            item.animation = gsap.to(item, {
              duration: this.tappedDuration,
              ease: 'linear',
              offset: 250
            })
            this.keys.push(item)
          } else {
            this.clearSequence()
            this.keys = []
            this.addClass('konami')
            this.forceTheme({
              white: '#dacd9e',
              primary: '#e22c1a',
              secondary: '#0f140e',
              black: '#02498f'
            })
            setTimeout(() => {
              if (!this.codeEgg.found) {
                this.findEgg('code')
              }
              this.removeClass(this.skewClass + (this.target.length - 1))
            }, this.skewDuration * 1000)
          }
        } else if (this.sequence.length === 0) {
          this.keys = []
        }

        let t = this.sequenceLength - 1
        if (t >= 0) {
          this.removeClass(this.skewClass + t)
        }

        this.sequenceLength = this.sequence.length
      }
    }
  }
</script>