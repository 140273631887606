<template>
  <div class="body__inner">
    <p data-font="big regular white">Yup, that's just a form like any other, send me a message !</p>
    <p data-font="medium regular white">You can call me Eliott or Mr. Pincemaille.</p>
    <div class="form"
         :class="{'form--sending': sending}"
         data-flex
         data-space="mt5">
      <div data-flex="column" data-space="px2">
        <label v-for="(input, i) in form"
               class="form__item"
               :for="input.name"
               :class="{'form__item--displaced': focused === input.name || vals[input.name].length > 0, 'form__item--focused': focused === input.name}"
               data-space="mb2"
               :key="i">
                  <span class="form__label"
                        :class="{'form__label--displaced': focused === input.name || vals[input.name].length > 0}">
                    {{ input.label }}
                    <i v-if="vals[input.name].length > 0"
                       :class="[input.valid ? 'icon-tick' : 'icon-cross']"></i>
                  </span>
          <input class="form__input"
                 :id="input.name"
                 :autocomplete="input.autoComplete"
                 v-model="vals[input.name]"
                 @focus="focused = input.name"
                 @blur="focused = null"/>
        </label>
      </div>
      <label class="form__item"
             for="message"
             data-space="mb2 ml4 mx2"
             data-col="grow"
             :class="{'form__item--displaced': focused === 'message' || messageValue.length > 0, 'form__item--focused': focused === 'message'}">
                <span class="form__label form__label--area"
                      :class="{'form__label--displaced': focused === 'message' || messageValue.length > 0}">
                  {{ 'message &lt;3' }}
                  <i v-if="messageValue.length > 0"
                     :class="[messageValue.length >= messageMin || (this.messageValue.toLowerCase() === 'nudes' && !this.eggNudes.found) ? 'icon-tick' : 'icon-cross']"></i>
                </span>
        <textarea class="form__area"
                  id="message"
                  v-model="messageValue"
                  @wheel="e => {e.stopPropagation()}"
                  @focus="focused = 'message'"
                  @blur="focused = null"></textarea>
        <span class="form__counter">{{ `${messageValue.length} / ${messageMin}` }}</span>
      </label>
    </div>
    <div data-space="mt2 px2"
         data-flex="justify-between items-center">
      <transition-group name="tpin" class="notes__container" tag="div">
        <p v-if="notes.success" data-font="primary medium bold" key="0">(´ｖ｀) : I got your message, thanks for sending !</p>
        <p v-else-if="notes.error" data-font="primary medium bold" key="1">（￣ε￣） : Something went wrong, please send me another message later.</p>
      </transition-group>
      <button ref="sendBtn"
              class="btn form__send"
              :class="{'form__send--sending': sending}"
              disabled>
        <span class="btn__text" @click="handleSend">Send my message &lt;3</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        focused: null,
        sending: false,
        form: {
          firstname: {
            name: 'firstname',
            label: 'firstName',
            autoComplete: 'given-name',
            check: (val) => val.length > 1,
            valid: false
          },
          lastname: {
            name: 'lastname',
            label: 'lastName',
            autoComplete: 'family-name',
            check: (val) => val.length > 1,
            valid: false
          },
          email: {
            name: 'email',
            label: '@email',
            autoComplete: 'email',
            check: (val) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(val).toLowerCase()),
            valid: false
          },
        },
        messageValue: '',
        vals: {
          firstname: '',
          lastname: '',
          email: ''
        },
        canSend: false,
        messageMin: 50,
        notes: {
          success: false,
          error: false
        }
      }
    },
    computed: {
      ...mapState({
        eggNudes: state => state.eggs.nudes,
        eggs: state => state.eggs
      }),
      allEggsFound (){
        return Object.keys(this.eggs).every(egg => this.eggs[egg].found)
      }
    },
    methods: {
      ...mapActions({
        findEgg: 'findEgg'
      }),
      handleSend () {
        this.notes.success = false
        this.notes.error = false
        if (this.canSend) {
          if (this.messageValue.toLowerCase() === 'nudes' && !this.eggNudes.found) {
            this.findEgg('nudes')
            this.messageValue = ''
          } else {
            this.sending = true
            this.$refs.sendBtn.disabled = true
            this.sendMail()
          }
        }
      },
      checkForm () {
        Object.keys(this.form).forEach(input => {
          this.form[input].valid = this.form[input].check(this.vals[input])
        })
        this.canSend = (this.messageValue.toLowerCase() === 'nudes' && !this.eggNudes.found) || (Object.keys(this.form).every(input => this.form[input].valid) && this.messageValue.length >= this.messageMin)
        this.$refs.sendBtn.disabled = !this.canSend
      },
      sendMail () {
        this.sendRequest()
          .then(r => {
            if (r.mailSent === 'ok') {
              this.notes.success = true
              this.vals.firstname = ''
              this.vals.lastname = ''
              this.vals.email = ''
              this.messageValue = ''
            } else {
              this.notes.error = true
            }
            this.sending = false
          })
      },
      async sendRequest () {
        const response = await fetch('./mail.php', {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            firstName: this.vals.firstname,
            lastName: this.vals.lastname,
            email: this.vals.email,
            message: (this.messageValue + (this.allEggsFound ? '\n\nThis person is a fucking genius !' : '')).replace(/\n/g, 'IIILiNeBrEaKIII').replace(/"/g, '&quot;').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;'),
            subject: 'Message de ' + this.vals.firstname + ' ' + this.vals.lastname + ' via elpince.dev',
          })
        })
        return response.json()
      }
    },
    watch: {
      vals: {
        deep: true,
        handler () {
          this.checkForm()
        }
      },
      messageValue () {
        this.checkForm()
      }
    }
  }
</script>