<template>
  <canvas class="grid-cnv"></canvas>
</template>

<script>
  import { mapState } from 'vuex'
  import Grid from '../classes/grid'

  export default {
    data () {
      return {
        grid: null
      }
    },
    computed: {
      ...mapState('themePicker', {
        currentTheme: state => state.current,
        forcedTheme: state => state.forced,
        themes: state => state.themes
      })
    },
    mounted () {
      if (!this.grid) {
        this.grid = new Grid({
          selector: '.grid-cnv',
          cursor: this.$cursor,
          theme: this.themes[this.currentTheme],
          settings: {
            nb: 25,
            radius: 1500,
            maxSpeed: 5,
            maxDisplacement: 1500,
            delay: {
              normal: .02,
              speed: .03,
              pos: 0.1,
              grad: 0.1
            }
          }
        }).init()
      }
    },
    watch: {
      currentTheme () {
        if (this.themes[this.currentTheme]) {
          this.grid.updateTheme(this.themes[this.currentTheme])
        }
      },
      forcedTheme () {
        if (this.forcedTheme) {
          this.grid.updateTheme(this.forcedTheme)
        }
      },
    }
  }
</script>