<template>
  <div class="found-egg">
    <svg version="1.1" class="found-egg__sun" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="400px" height="300px" viewBox="0 0 400 400" enable-background="new 0 0 400 400" xml:space="preserve" preserveAspectRatio="xMidYMin slice">
      <defs>
        <radialGradient id="sunGrad" cx="200" cy="200" :r="radius" gradientUnits="userSpaceOnUse">
          <stop offset="0" style="stop-color: var(--primary);stop-opacity: 1;" />
          <stop offset="1" style="stop-color: var(--primary);stop-opacity: 0;" />
        </radialGradient>
        <linearGradient id="gradientMask" gradientTransform="rotate(90)">
          <stop offset="0" stop-color="white" stop-opacity="1" />
          <stop offset=".4" stop-color="white" stop-opacity="1" />
          <stop offset=".6" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <mask id="bottomMask" x="0" y="0" width="1" height="1" >
          <rect x="0" y="0" width="400" height="400" fill="url(#gradientMask)"/>
       </mask>
      </defs>
      <g mask="url(#bottomMask)">
        <g class="sun_group">
          <path fill="url(#sunGrad)" d="M199.999,199.999L9.034,251.167c4.514,16.89,11.201,32.891,19.756,47.679L199.999,199.999 L199.999,199.999z"/>
          <path fill="url(#sunGrad)" d="M251.168,9.034C234.846,4.672,217.697,2.333,200,2.333v197.662L251.168,9.034z"/>
          <path fill="url(#sunGrad)" d="M148.832,9.034c-16.316,4.383-32.337,10.932-47.662,19.781L200,199.995L148.832,9.034z"/>
          <path fill="url(#sunGrad)" d="M60.207,60.202c-11.938,11.955-22.539,25.636-31.386,40.962L200,199.995L60.207,60.202z"/>
          <path fill="url(#sunGrad)" d="M9.039,148.828c-4.362,16.322-6.701,33.471-6.699,51.167H200L9.039,148.828z"/>
          <path fill="url(#sunGrad)" d="M199.998,199.999L60.227,339.77c12.171,12.172,25.926,22.76,40.924,31.438L199.998,199.999L199.998,199.999z"/>
          <path fill="url(#sunGrad)" d="M200,199.998L200,199.998l139.772,139.772c12.172-12.172,22.759-25.927,31.437-40.925L200,199.998L200,199.998z"/>
          <path fill="url(#sunGrad)" d="M251.169,390.965c16.891-4.514,32.892-11.201,47.679-19.757L200,199.999L251.169,390.965z"/>
          <path fill="url(#sunGrad)" d="M200,199.998L200,199.998L200,199.998L200,199.998l-51.171,190.967c16.323,4.363,33.473,6.702,51.171,6.702V199.998L200,199.998z"/>
          <path fill="url(#sunGrad)" d="M200,199.998l190.965,51.17c4.362-16.322,6.701-33.471,6.701-51.168c0,0,0-0.001,0-0.001L200,199.998L200,199.998z"/>
          <path fill="url(#sunGrad)" d="M200,199.998l190.965-51.169c-4.515-16.89-11.201-32.891-19.758-47.678L200,199.998L200,199.998z"/>
          <path fill="url(#sunGrad)" d="M200,199.997l139.771-139.77c-12.172-12.171-25.927-22.759-40.925-31.436L200,199.997L200,199.997z"/>
        </g>
      </g>
    </svg>
    <img v-if="isshenron" class="found-egg__shenron" src="../assets/images/shenron.png"/>
    <i v-else class="found-egg__icon"
       :class="[icon]"></i>
  </div>
</template>

<script>
  import { gsap } from 'gsap'

  export default {
    props: ['icon', 'isshenron'],
    data () {
      return {
        radius: 190,
        rad: {
          min: 160,
          max: 190
        },
        delay: {
          min: 2,
          max: 2.5
        }
      }
    },
    mounted () {
      this.animateRadius()
    },
    methods: {
      animateRadius () {
        gsap.to(this, {
          ease: 'Power2.easeInOut',
          duration: Math.random() * (this.delay.max - this.delay.min) + this.delay.min,
          radius: Math.random() * (this.rad.max - this.rad.min) + this.rad.min,
          onComplete: () => {
            this.animateRadius()
          }
        })
      }
    }
  }
</script>