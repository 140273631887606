<template>
  <div class="body__inner">
    <p data-font="big regular white">Click the <span data-font="bold">big button</span> to have a look !</p>
    <button ref="bigBtn" class="btn btn--big"
            data-space="mt5">
      <span class="btn__text" @click="handleBigBtn">I am the big button &lt;3</span>
    </button>
  </div>
</template>

<script>
  import { mapMutations, mapActions } from 'vuex'

  export default {
    data () {
      return {
        shakeDuration: 1,
        bigBtnActive: true,
      }
    },
    methods: {
      ...mapMutations({
        handlePortfolio: 'handlePortfolio'
      }),
      ...mapActions({
        playClass: 'playClass'
      }),
      handleBigBtn () {
        if (this.bigBtnActive) {
          this.bigBtnActive = false
          this.playClass({
            value: 'shaky',
            duration: this.shakeDuration
          })
          setTimeout(() => {
            this.handlePortfolio(true)
          }, this.shakeDuration * 1000 / 2)
          setTimeout(() => {
            this.bigBtnActive = true
          }, this.shakeDuration * 1000)
        }
      },
    }
  }
</script>