<template>
  <div class="noob-menu container">
    <div class="noob-menu__holder"
         :class="{'noob-menu__holder--minus': !plusMenu}"
         :style="styles[menuItem]">
      <button v-for="(item, index) in items"
              ref="menuItems"
              class="noob-menu__item"
              :class="{'noob-menu__item--active': index === menuItem}"
              :key="`menu-${index}`"
              @click="() => { goToMenu(index) }">{{ item }}</button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'noob-menu',
  data () {
    return {
      items: [
        'about',
        'portfolio',
        'contact',
        'guide'
      ],
      styles: []
    }
  },
  computed: {
    ...mapState({
      menuItem: state => state.menuItem,
      plusMenu: state => state.plusMenu,
    })
  },
  mounted () {
    setTimeout(() => {
      this.initPos()
    }, 1000)
  },
  methods: {
    ...mapMutations({
      goToMenu: 'goToMenu'
    }),
    initPos () {
      let l = 0;
      for (let i = 0; i < this.items.length; i++) {
        this.styles.push({
          '--left': `${l}px`
        })
        l += this.$refs.menuItems[i].getBoundingClientRect().width
      }
      let r = 0
      for (let i = this.items.length - 1; i >= 0; i--) {
        this.styles[i]['--right'] = `${r}px`
        r += this.$refs.menuItems[i].getBoundingClientRect().width
      }
    }
  }
}
</script>