<template>
  <div class="body__inner">
    <p data-font="big regular white">Don't feel bad about it, you just need to follow those easy steps :</p>
    <ol data-space="mt1">
      <li data-font="big regular white">locate the <i class="icon-list inline-block" data-font="primary normal top"></i> button on the top-right corner of your screen.</li>
      <li data-font="big regular white"><span data-font="semi primary">Click</span> it !</li>
      <li data-font="big regular white">Look at the awesome <span data-font="semi primary">Sidebar&reg;</span> that appeared on the right of your screen.</li>
      <li data-font="big regular white">In this <span data-font="semi primary">Sidebar&reg;</span> you have a list of <span data-font="semi primary">Hints</span></li>
      <li data-font="big regular white">Each of those <span data-font="semi primary">Hints</span> will help you find an <span data-font="semi primary">Egg</span></li>
      <li data-font="big regular white"><span data-font="bold primary">READ</span> a <span data-font="semi primary">Hint</span></li>
      <li data-font="big regular white">Understand it <span data-font="medium white">(google is your friend)</span></li>
      <li data-font="big regular white">Use your <span data-font="semi primary">mouse/keyboard/brain/eyes</span> to find the <span data-font="semi primary">Egg<transition name="fade"><i v-if="!theEgg.found" class="icon-egg inline-block" data-font="normal primary top" @click="() => {findEgg('theEgg')}"></i></transition></span></li>
      <li data-font="big regular white">Profit (?)</li>
      <li data-font="big regular white">Start over from <span data-font="semi primary">Step 1</span></li>
    </ol>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapState({
        theEgg: state => state.eggs.theEgg
      })
    },
    methods: {
      ...mapActions({
        findEgg: 'findEgg'
      })
    }
  }
</script>