<template>
    <div class="container container--small found-egg__container">
      <div class="container__inner">
        <div data-flex="column justify-center items-center"
             data-space="px4">
          <Reward :icon="eggs[foundEgg[0]].icon" :isshenron="eggs[foundEgg[0]].isShenron"></Reward>
          <p class="relative" data-font="huge semi" data-space="px4">{{ eggs[foundEgg[0]].success }}</p>
          <div data-flex="justify-center items-center" data-space="mt5">
            <button class="btn btn--big">
              <span class="btn__text" @click="() => {validate(foundEgg[0])}">Take the Egg &lt;3</span>
            </button>
            <button v-if="foundEgg[0] === 'free'" class="btn btn--big" data-space="ml4">
              <span class="btn__text" @click="() => {validate(null)}">Leave the Egg =(</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import Reward from './Reward'

  export default {
    components: {
      Reward
    },
    computed: {
      ...mapState({
        foundEgg: state => state.foundEgg,
        eggs: state => state.eggs
      })
    },
    methods: {
      ...mapMutations({
        validate: 'validateReward'
      })
    }
  }
</script>