export default {
  playClass (context, opts) {
    context.commit('addClass', opts.value)

    if (opts.duration && opts.duration > 0) {
      setTimeout(() => {
        context.commit('removeClass', opts.value)
      }, opts.duration * 1000)
    }
  },
  findEgg (context, val) {
    setTimeout(() => {
      context.commit('validateEgg', val)
    }, 100)
  }
}