<template>
  <div class="container container--small">
    <div class="container__inner">
      <div class="validate">
        <p v-for="text in alert.texts" v-html="text.content" class="validate__text" :class="text.classes" :data-font="text.font" :key="text.id"></p>
        <div data-space="mt4" data-flex="justify-center">
          <button v-for="(btn, i) in alert.btns" v-html="btn.content" :class="btn.classes" :data-space="i > 0 ? 'ml2' : null" :key="btn.id" @click="btn.callback"></button>
        </div>
        <transition name="tpin">
          <img v-if="!ballFound" @click="pickBallUp" class="ball validate__ball" src="../assets/images/ball_6.svg" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    computed: {
      ...mapState({
        alert: state => state.needsValidation,
        ballFound: state => state.balls[6]
      })
    },
    methods: {
      ...mapMutations({
        findBall: 'findBall',
        clearValidation: 'clearValidation'
      }),
      pickBallUp () {
        this.findBall(6)
        this.clearValidation()
      }
    }
  }
</script>