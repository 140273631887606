export default {
  ascii: `─────────▄███████████▄▄──────────────
──────▄██▀──────────▀▀██▄────────────
────▄█▀────────────────▀██───────────
──▄█▀────────────────────▀█▄─────────
─█▀──██──────────────██───▀██────────
█▀──────────────────────────██───────
█──███████████████████───────█───────
█────────────────────────────█───────
█────────────────────────────█───────
█────────────────────────────█───────
█────────────────────────────█───────
█────────────────────────────█───────
█▄───────────────────────────█───────
▀█▄─────────────────────────██───────
─▀█▄───────────────────────██────────
──▀█▄────────────────────▄█▀─────────
───▀█▄──────────────────██───────────
─────▀█▄──────────────▄█▀────────────
───────▀█▄▄▄──────▄▄▄███████▄▄───────
────────███████████████───▀██████▄───
─────▄███▀▀────────▀███▄──────█─███──
───▄███▄─────▄▄▄▄────███────▄▄████▀──
─▄███▓▓█─────█▓▓█───████████████▀────
─▀▀██▀▀▀▀▀▀▀▀▀▀███████████────█──────
────█─▄▄▄▄▄▄▄▄█▀█▓▓─────██────█──────
────█─█───────█─█─▓▓────██────█──────
────█▄█───────█▄█──▓▓▓▓▓███▄▄▄█──────
────────────────────────██──────────
────────────────────────██───▄███▄───
────────────────────────██─▄██▓▓▓██──
───────────────▄██████████─█▓▓▓█▓▓██▄
─────────────▄██▀───▀▀███──█▓▓▓██▓▓▓█
─▄███████▄──███───▄▄████───██▓▓████▓█
▄██▀──▀▀█████████████▀▀─────██▓▓▓▓███
██▀─────────██──────────────██▓██▓███
██──────────███──────────────█████─██
██───────────███──────────────█─██──█
██────────────██─────────────────█───
██─────────────██────────────────────
██─────────────███───────────────────
██──────────────███▄▄────────────────
███──────────────▀▀███───────────────
─███─────────────────────────────────
──███────────────────────────────────`
}