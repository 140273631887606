export default {
  current: 0,
  forced: null,
  themes: [
    {
      white: '#ffcc29',
      primary: '#f58634',
      secondary: '#00af91',
      black: '#007965'
    },
    {
      white: '#f1f4df',
      primary: '#10eaf0',
      secondary: '#0028ff',
      black: '#24009c'
    },
    {
      white: '#f6f078',
      primary: '#01d28e',
      secondary: '#434982',
      black: '#730068',
      ball: true
    },
    {
      white: '#fbe555',
      primary: '#fb9224',
      secondary: '#f45905',
      black: '#2a1a5e',
      sunset: true
    },
    {
      white: '#f4ff61',
      primary: '#a8ff3e',
      secondary: '#32ff6a',
      black: '#27aa80'
    },
    {
      white: '#eaeaea',
      primary: '#00d1cd',
      secondary: '#f30067',
      black: '#444444'
    },
  ]
}