<template>
  <div class="logo" :class="{'logo--force': force, 'logo--active': this.logoEgg.found, 'logo--ball': ball}" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <svg class="toilet__container" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="162px" height="168.333px" viewBox="0 0 162 168.333">
      <defs>
        <mask id="toiletMask">
          <path class="toilet__stream--mask" d="M95.833,105.667c0-28.098,25.718-50.834,57.5-50.834"/>
        </mask>
      </defs>
      <g class="toilet">
        <path class="toilet__shape" d="M119.333,105.667c0,13.645-7.171,25.534-17.833,31.898v-2.319v-12.825v-8.755v-7.999l-67.166,0 c0,27.246,22.087,49.333,49.332,49.333c0.827-0.002,2.551-0.07,2.594-0.074c5.362-0.29,10.485-1.487,15.24-3.428 c17.777-7.253,30.333-25.02,30.333-45.832H119.333z M49.014,118.167L89,118.167v23.945c-1.742,0.252-3.521,0.39-5.333,0.389 C67.714,142.501,54.132,132.356,49.014,118.167z"/>
        <path class="toilet__shape" d="M77.5,88.168L77.5,88.168l-12.167,0l0.001-49.001c0-1.658-1.344-3-3-3h-32c-1.657,0.001-3,1.343-3,3l0,58.5 c0,1.658,1.344,3,3,3l47.167,0c3.451,0,6.25-2.798,6.25-6.25C83.75,90.966,80.951,88.168,77.5,88.168z M52.834,85.167 c0,1.657-1.343,3-3,3l-7,0c-1.656,0-3-1.343-3-3l0.001-33.5c0-1.657,1.343-2.999,2.999-2.999l7.001-0.001c1.656,0,3,1.343,3,3 V85.167z"/>
        <path class="toilet__shape toilet__clap" d="M131.833,95.167c0-3.864-3.134-6.999-7-7l-47.333,0v12.5h54.333V95.167z"/>
      </g>
      <path class="toilet__stream" mask="url(#toiletMask)" d="M95.833,105.667c0-28.098,25.718-50.834,57.5-50.834"/>
    </svg>
    <div class="toilet__ball-container">
      <transition name="tpin">
        <img v-if="!ballFound" @click="() => {findBall(2)}" class="ball toilet__ball" src="../assets/images/ball_2.svg"/>
      </transition>
    </div>
    <transition name="bubblin">
      <div v-if="!logoEgg.found && !wordFound && hovering" class="bubble">
        <p data-font="big black semi nowrap">Howdy ! I'm the logo.</p>
        <p data-font="big black semi nowrap">I'm not really used to seeing faces, y'see...</p>
        <div data-flex>
          <p data-font="big black semi nowrap" data-space="mr1">Yeah well, y'know, that's because I'm a</p>
          <label class="bubble__label">
            <input class="bubble__input" :maxlength="target.length" v-model="inputVal" />
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    data () {
      return {
        inputVal: '',
        animDuration: 2,
        animTimer: null,
        target: 'toilet',
        hovering: false,
        logoClass: [],
        wordFound: false,
        active: false,
        force: false,
        ball: false
      }
    },
    computed: {
      ...mapState({
        logoEgg: state => state.eggs.logo,
        ballFound: state => state.balls[2]
      })
    },
    methods: {
      ...mapMutations({
        findBall: 'findBall'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      })
    },
    mounted () {
      if (this.logoEgg.found) {
        this.ball = true
      }
    },
    watch: {
      inputVal () {
        if (this.inputVal === this.target && !this.logoEgg.found) {
          this.inputVal = ''
          this.wordFound = true
          this.force = true
          this.animTimer = setTimeout(() => {
            this.force = false
            this.ball = true
            this.findEgg('logo')
          }, this.animDuration * 1000)
        }
      },
      logoEgg: {
        deep: true,
        handler () {
          if (!this.logoEgg.found) {
            this.ball = false
            this.wordFound = false
          }
        }
      }
    }
  }
</script>