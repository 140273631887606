<template>
  <div class="body__inner">
    <p data-font="big regular white">Here is a non-exhaustive list (the most important is here though) !</p>
    <p data-font="big regular white" data-space="mt5">I made this website because :</p>
    <ul data-space="mt1">
      <li data-font="big regular white">I can do it</li>
      <li data-font="big regular white">I want you to see that I can do it</li>
      <li data-font="big regular white">It's fun</li>
      <li data-font="big regular white">I have fun working on it</li>
      <li data-font="big regular white">I can do whatever I like, it's my project</li>
      <li data-font="big regular white">I like the concept of doing whatever I like</li>
      <li data-font="big regular white">My mom and dad would be pr<transition name="tpin" mode="out-in"><img v-if="display" class="ball ball__description" @click="() => {findBall(1)}" src="../assets/images/ball_1.svg" /><span v-else>o</span></transition>ud (maybe)</li>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    computed: {
      ...mapState({
        ballFound: state => state.balls[1]
      }),
      ...mapState('themePicker', {
        currentTheme: state => state.current,
        themes: state => state.themes
      }),
      display () {
        return !this.ballFound && this.themes[this.currentTheme].ball
      }
    },
    methods: {
      ...mapMutations({
        findBall: 'findBall'
      })
    }
  }
</script>