export default class Cursor {
  constructor () {
    this.rawX = null
    this.rawY = null
    this.posX = null
    this.posY = null
    this.prevX = null
    this.prevY = null
    this.dist = null
    this.normalX = null
    this.normalY = null
    this.deg = null
    this.interval = null
    this.inited = false
    this.enterTimer = null
    this.leftBody = false
  }

  init () {
    document.addEventListener('mousemove', e => {
      this.firstMove(e)
    }, {once: true})
    document.querySelector('body').addEventListener('mouseenter', () => {this.enterBody()})
    document.querySelector('body').addEventListener('mouseleave', () => {this.leaveBody()})

    return this
  }
  firstMove (e) {
    document.addEventListener('mousemove', e => {
      this.move(e)
    })
    this.interval = setInterval(() => {
      this.updatePos()
    }, 1000 / 60)

    this.rawX = e.clientX
    this.rawY = e.clientY
    this.posX = e.clientX
    this.posY = e.clientY
    this.prevX = e.clientX
    this.prevY = e.clientY

    this.inited = true
  }
  move (e) {
    this.rawX = e.clientX
    this.rawY = e.clientY
  }
  enterBody () {
    this.leftBody = false
    this.distBlocked = true
    if (this.enterTimer) {
      clearTimeout(this.enterTimer)
    }
    this.enterTimer = setTimeout(() => {
      this.distBlocked = false
      this.enterTimer = null
    }, 50)
  }
  leaveBody () {
    this.leftBody = true
  }
  updatePos () {
    // update previous cursor position
    this.prevX = this.posX
    this.prevY = this.posY

    // update current cursor position
    this.posX = this.rawX
    this.posY = this.rawY

    // update position difference on both axis
    const difX = this.posX - this.prevX
    const difY = this.posY - this.prevY

    // update distance from previous position
    if (this.distBlocked) {
      this.dist = 0
    } else  {
      this.dist = Math.floor(Math.sqrt(difX * difX + difY * difY))
    }

    // update direction
    const max = Math.max(Math.abs(difX), Math.abs(difY))
    this.normalX = difX / max
    this.normalY = difY / max

    this.deg = Math.atan2(this.normalY, this.normalX) * 180 / Math.PI + 180
  }
}