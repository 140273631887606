export default {
  appClasses: [],
  sidebarOpened: false,
  consoleOpened: false,
  portfolioOpened: false,
  foundEgg: [],
  foundInstrument: false,
  needsValidation: null,
  visits: 0,
  firstName: null,
  menuItem: null,
  plusMenu: true,
  eggs: {
    free: {
      index: 0,
      title: 'A free egg',
      hint: 'Wait... Did you really NOT take this one ?',
      success: 'Here is a free Egg, will you take it, or leave it ?',
      successAlt: 'You worked soooo hard to get this one, right ?',
      found: false,
      icon: 'icon-free'
    },
    leprechaun: {
      index: 1,
      title: 'Find the leprechaun',
      hint: 'He only appears when the sun sets',
      success: 'Yup, I know it definitely wasn\'t a rainbow.',
      found: false,
      icon: 'icon-clover'
    },
    dog: {
      index: 2,
      title: 'Hold the "dog"',
      hint: 'Just do it',
      success: 'Qu\'est qu\'on attend ???',
      found: false,
      icon: 'icon-dog'
    },
    reload: {
      index: 3,
      title: 'Come back later',
      hint: [
        'please',
        'Even later',
        'Yeah, still a bit later',
        'Gotta try yet another time'
      ],
      success: 'If I had analytics on here, this Egg would mess theme up.',
      found: false,
      icon: 'icon-reset'
    },
    // plane: {
    //   index: 4,
    //   title: 'Reach mach speed',
    //   hint: 'I recommend using your elbow',
    //   success: 'I hope you didn\'t break anything near your PC.' ,
    //   found: false,
    //   icon: 'icon-plane'
    // },
    nudes: {
      index: 5,
      title: 'Send nudes',
      hint: 'Don\'t worry, I won\'t spread them.',
      success: 'If you\'re wondering : No, I won\'t get a message that just says "nudes".' ,
      found: false,
      icon: 'icon-nudes'
    },
    console: {
      index: 6,
      title: 'Find a real person',
      hint: 'He\'s stuck in a game. Open his <a data-font="white" href="https://en.wikipedia.org/wiki/Console_(computer_games)#:~:text=Typically%20it%20can%20be%20toggled,is%20usually%20hidden%20by%20default." target="_blank">console</a> to meet and command him.',
      success: 'He\'s a bit weird but won\'t bite.',
      found: false,
      icon: 'icon-console',
      hasHtml: true
    },
    here: {
      isHiddenEgg: true,
      index: 7,
      title: 'It\'s just there',
      hint: 'But will you find it ?',
      success: 'Heeey, another proof that your brain\'s not just for show !' ,
      found: false,
      icon: 'icon-thumb'
    },
    jetpack: {
      index: 8,
      title: 'Spawn a jetpack',
      hint: 'Ask a real person as if you were a PC player.',
      success: 'And I\'m a ROCKETMAAAAAAAN !!!',
      found: false,
      icon: 'icon-jetpack'
    },
    code: {
      index: 9,
      title: 'The usual cheat',
      hint: 'You can google it.',
      success: 'This is "sooooooo original".',
      found: false,
      icon: 'icon-controller'
    },
    logo: {
      index: 10,
      title: 'My logo\'s shape',
      hint: 'And my first name\'s anagram.',
      success: 'Am I proud of this ? Maybe.',
      found: false,
      icon: 'icon-toilet'
    },
    theEgg: {
      index: 11,
      title: 'This one\'s easy',
      hint: 'If you properly used your mouse/keyboard/brain/eyes, you\'d already found it.',
      success: 'I knew you could make it !',
      found: false,
      icon: 'icon-brain'
    },
    mario: {
      index: 12,
      title: 'Play mario\'s theme',
      hint: 'You might need a real person and an instrument.',
      success: 'Well now I\'m impressed, I never thought you\'d make it here.',
      found: false,
      icon: 'icon-shroom'
    },
    shenron: {
      index: 13,
      title: 'Invoke Shenron',
      hint: 'Those balls could be anywhere, anytime.',
      success: 'Rawr !',
      found: false,
      icon: 'icon-ball2',
      isShenron: true
    }
  },
  balls: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  }
}
