<template>
  <label class="volume" for="volume-picker">
    <i class="volume__icon"
       :class="[muted ? 'icon-sound-off' : 'icon-sound']"
       @click="muted = !muted"></i>
    <transition name="fade">
      <div class="volume__container">
        <input v-if="!muted"
               id="volume-picker"
               class="volume__input"
               name="volume-picker"
               v-model="volume"
               type="range"
               min="0"
               max="1"
               step="0.01" />
        <transition name="tpin">
          <img v-if="!ballFound && !muted" @mousedown="getBall" class="ball volume__ball" src="../assets/images/ball_3.svg" :style="offset" />
        </transition>
      </div>
    </transition>
  </label>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    data () {
      return {
        volume: 0,
        muted: false
      }
    },
    computed: {
      ...mapState({
        ballFound: state => state.balls[3]
      }),
      offset () {
        return {
          left: `calc(${this.volume * 100}% - ${this.volume * 2}rem)`
        }
      }
    },
    mounted () {
      this.volume = this.$aPlayer.volume
    },
    watch: {
      volume () {
        if (this.$aPlayer.volume !== this.volume) {
          this.$aPlayer.changeVolume(this.volume)
        }
      },
      muted () {
        if (this.$aPlayer.muted !== this.muted) {
          this.$aPlayer.mute(this.muted)
        }
      }
    },
    methods: {
      ...mapMutations({
        findBall: 'findBall'
      }),
      getBall (e) {
        e.preventDefault()
        this.findBall(3)
      }
    }
  }
</script>