import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cursor from './plugins/cursor'
import audioPlayer from './plugins/audio-player'

Vue.use(cursor)
Vue.use(audioPlayer)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
