export default class Grid {
  constructor (opts) {
    this.cnv = document.querySelector(opts.selector)
    this.ctx = this.cnv.getContext('2d')
    this.cursor = opts.cursor
    this.theme = opts.theme
    this.size = {x: 0, y: 0,}
    this.opts = {
      ...opts.settings,
      gap: opts.settings.radius / opts.settings.nb
    }
    this.pool = 0
    this.pos = {x: 0, y: 0}
    this.displacement = {x: 0, y: 0}
    this.normal = {x: 0, y: 0}
    this.lastNormal = {x: 0, y: 0}
    this.gradPool = 0
  }
  init () {
    window.addEventListener('resize', () => {this.resize()})
    this.resize()
    this.frame(this.ctx, this.theme, this.size)
    return this
  }
  resize () {
    this.size.x = window.innerWidth
    this.size.y = window.innerHeight
    this.cnv.width = this.size.x
    this.cnv.height = this.size.y
  }
  update () {
    const gap = {
      x: this.pos.x % this.opts.gap,
      y: this.pos.y % this.opts.gap
    }
    gap.x = gap.x <= this.opts.gap / 2 ? gap.x : -(this.opts.gap - gap.x)
    gap.y = gap.y <= this.opts.gap / 2 ? gap.y : -(this.opts.gap - gap.y)

    const closest = {
      x: this.pos.x - gap.x,
      y: this.pos.y - gap.y,
    }
    const rows = new Array(this.opts.nb)
    for (let i = 0; i < (this.opts.nb + 1) / 2; i++) {
      rows[Math.floor(this.opts.nb / 2) + i] = this.setRow(closest.y + i * this.opts.gap, closest)
      if (i > 0) {
        rows[Math.floor(this.opts.nb / 2) - i] = this.setRow(closest.y - i * this.opts.gap, closest)
      }
    }
    return rows
  }
  setRow (y, closest) {
    const arr = new Array(this.opts.nb)
    for (let i = 0; i < (this.opts.nb + 1) / 2; i++) {
      arr[Math.floor(this.opts.nb / 2) + i] = this.setPoint(closest.x + i * this.opts.gap, y)
      if (i > 0) {
        arr[Math.floor(this.opts.nb / 2) - i] = this.setPoint(closest.x - i * this.opts.gap, y)
      }
    }
    return arr
  }
  setPoint (x, y) {
    let point = null
    const dif = {
      x: this.pos.x - x,
      y: this.pos.y - y
    }
    const dist = Math.sqrt(dif.x * dif.x + dif.y * dif.y)
    if (dist < this.opts.radius / 2 + 1) {
      const displacement = {
        x: this.displacement.x * dist / this.opts.radius / 2,
        y: this.displacement.y * dist / this.opts.radius / 2
      }
      point = {
        x: x - displacement.x * this.opts.maxDisplacement,
        y: y - displacement.y * this.opts.maxDisplacement
      }
    }
    return point
  }
  displace () {
    const target = this.cursor.dist <= this.opts.maxSpeed ? this.cursor.dist : this.opts.maxSpeed
    const dif = target / this.opts.maxSpeed - this.pool
    this.pool += dif * this.opts.delay.speed

    let normal = this.lastNormal
    if (!isNaN(this.cursor.deg)) {
      normal.x = this.cursor.normalX
      normal.y = this.cursor.normalY
      this.lastNormal = normal
    }
    const nDif = {
      x: normal.x - this.normal.x,
      y: normal.y - this.normal.y
    }
    this.normal.x += nDif.x * this.opts.delay.normal
    this.normal.y += nDif.y * this.opts.delay.normal
    this.displacement.x = this.normal.x * this.pool
    this.displacement.y = this.normal.y * this.pool

    const pDif = {
      x: this.cursor.posX - this.pos.x,
      y: this.cursor.posY - this.pos.y
    }
    this.pos.x += pDif.x * this.opts.delay.pos
    this.pos.y += pDif.y * this.opts.delay.pos
  }
  makeGradient () {
    const p = {
      x: this.pos.x - this.displacement.x * this.opts.maxDisplacement / 5,
      y: this.pos.y - this.displacement.y * this.opts.maxDisplacement / 5
    }
    const target = this.cursor.leftBody ? 0 : 1
    const dif = target - this.gradPool
    this.gradPool += dif * this.opts.delay.grad

    const g = this.ctx.createRadialGradient(p.x, p.y, 0, p.x, p.y, this.opts.radius / 2)
    g.addColorStop(0, this.theme.secondary)
    g.addColorStop(.7 * this.gradPool, this.theme.secondary)
    g.addColorStop(.9 * this.gradPool, this.theme.black)

    return g
  }
  updateTheme (theme) {
    this.theme = theme
  }
  frame () {
    this.ctx.clearRect(0, 0, this.size.x, this.size.y)
    this.ctx.strokeStyle = this.makeGradient()

    this.displace()
    const rows = this.update()

    this.ctx.beginPath()
    rows.forEach((row, i) => {
      row.forEach((point, j) => {
        if (point) {
          if (rows[i - 1] && rows[i - 1][j]) {
            this.ctx.moveTo(rows[i - 1][j].x, rows[i - 1][j].y)
            this.ctx.lineTo(point.x, point.y)
          }
          if (rows[i][j - 1]) {
            this.ctx.moveTo(rows[i][j - 1].x, rows[i][j - 1].y)
            this.ctx.lineTo(point.x, point.y)
          }
        }
      })
    })
    this.ctx.stroke()
    this.ctx.closePath()

    window.requestAnimationFrame(() => this.frame())
  }
}