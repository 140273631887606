<template>
  <div class="container">
    <div class="container__inner wayh__container"
         :class="{'wayh__container--active': menuItem !== null}"
         data-flex="column">
      <div class="wayh" data-font="semi" data-space="px4">
        <div v-for="(item, i) in items"
             class="wayh__item"
             :class="{'wayh__item--active': i === menuItem}"
             data-space="px1"
             :key="i"
             @click="() => { goToMenu(i) }">
          <p v-if="item.title.letters.length > 0"
             class="wayh__title"
             data-font="fit">
            <span v-for="(letter, j) in item.title.letters"
                  class="wayh__title__letter"
                  :style="[i === menuItem ? letter.style : {}]"
                  data-font="primary fit"
                  :key="j">{{ letter.value }}</span>
          </p>
          <p v-if="item.subtitle.letters.length > 0"
             class="wayh__subtitle"
             :class="{ 'wayh__subtitle--reverse': item.subtitle.reverse }"
             data-font="huge high"
             data-space="pl1">
            <span v-for="(l, k) in item.subtitle.letters"
                  class="wayh__subtitle__letter"
                  :style="[i === menuItem ? l.style : {}]"
                  data-font="white high"
                  :key="k">{{ l.value }}</span>
          </p>
        </div>
      </div>
      <div class="body" data-space="mt5 px5">
        <div class="body__section"
             data-space="px5"
             :class="{'body__section--active': menuItem === 0}">
          <Description></Description>
        </div>
        <div class="body__section"
             data-space="px5"
             :class="{'body__section--active': menuItem === 1}"
             data-font="big regular white">
          <BigBtn></BigBtn>
        </div>
        <div class="body__section"
             data-space="px5"
             :class="{'body__section--active': menuItem === 2}">
          <Form></Form>
        </div>
        <div class="body__section"
             data-space="px5"
             :class="{'body__section--active': menuItem === 3}">
          <LostMuch></LostMuch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import Form from './Form'
  import Description from './Description'
  import BigBtn from './BigBtn'
  import LostMuch from './LostMuch'

  export default {
    components: {
      Form,
      Description,
      BigBtn,
      LostMuch
    },
    computed: {
      ...mapState({
        menuItem: state => state.menuItem
      })
    },
    data () {
      return {
        switchDuration: .5,
        switching: null,
        items: [
          {
            title: {
              string: 'Why',
              letters: []
            },
            subtitle: {
              string: 'did I make this website ?',
              letters: []
            }
          },
          {
            title: {
              string: 'Are you',
              letters: []
            },
            subtitle: {
              string: 'interested in stuff I\'ve done ?',
              letters: []
            }
          },
          {
            title: {
              string: 'Here',
              letters: []
            },
            subtitle: {
              string: 'is a way to contact me :',
              letters: [],
            }
          },
          {
            title: {
              string: '?',
              letters: []
            },
            subtitle: {
              string: 'Lost much',
              letters: [],
              reverse: true
            }
          }
        ]
      }
    },
    created () {
      this.items.forEach(item => {
        ['title', 'subtitle'].forEach(type => {
          Array.from(item[type].string).forEach((letter, i) => {
            const base = type === 'title' ? 0 : 0.05
            const mult = type === 'title' ? 0.05 : 0.015
            const prop = type === 'title' ? 'top' : 'opacity'
            const delay = item[type].reverse ? (item[type].string.length - i) * mult + base : i * mult + base
            item[type].letters.push({
              value: letter,
              style: {
                transition: prop + ' .2s ease ' + delay + 's, color .3s ease 0s'
              }
            })
          })
        })
      })
    },
    watch: {
      menuItem () {
        clearTimeout(this.switching)
        this.switching = setTimeout(() => {
          this.switching = null
        }, this.switchDuration * 1000)
      }
    },
    methods: {
      ...mapMutations({
        goToMenu: 'goToMenu'
      })
    }
  }
</script>