<template>
  <div class="reset">
    <i class="icon-reset reset__icon" @click="askValidation"></i>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapState({
        eggs: state => state.eggs,
        balls: state => state.balls,
        foundInstrument: state => state.foundInstrument,
        firstName: state => state.firstName,
        visits: state => state.visits
      }),
      eggsFound () {
        return Object.keys(this.eggs).filter(egg => this.eggs[egg].found).map(egg => egg)
      },
      ballsFound () {
        return Object.keys(this.balls).filter(ball => this.balls[ball] === true).map(ball => ball)
      }
    },
    methods: {
      ...mapMutations({
        retrieveEggs: 'retrieveEggs',
        retrieveBalls: 'retrieveBalls',
        findInstrument: 'findInstrument',
        clearEggs: 'clearEggs',
        clearBalls: 'clearBalls',
        queueValidation: 'queueValidation',
        clearValidation: 'clearValidation',
        clearInstrument: 'clearInstrument',
        setVisits: 'setVisits',
        setName: 'setName'
      }),
      ...mapActions({
        findEgg: 'findEgg'
      }),
      askValidation () {
        let text, btns
        if (this.eggsFound.length > 0 || this.ballsFound.length > 0) {
          text = `Slow down there ! Do you really want to lose aaaall of the ${this.eggsFound.length} Eggs and ${this.ballsFound.length} dragonballs you've found ?`
          btns = [
            {
              id: 0,
              content: `<span class="btn__text">Yeah I'm fiiine</span>`,
              classes: 'btn',
              callback: () => {
                localStorage.clear()
                this.clearValidation()
                this.clearEggs()
                this.clearBalls()
                this.clearInstrument()
                this.setVisits(1)
                this.setName(null)
              }
            },
            {
              id: 1,
              content: `<span class="btn__text">I beg you, no !</span>`,
              classes: 'btn',
              callback: () => {
                this.clearValidation()
              }
            }
          ]
        } else {
          text = `What do you want to reset, you didn't find a single Egg yet ?`
          btns = [
            {
              id: 1,
              content: `<span class="btn__text">Sorry, I'll try harder</span>`,
              classes: 'btn',
              callback: () => {
                this.clearValidation()
              }
            }
          ]
        }
        this.queueValidation({
          texts: [
            {
              id: 0,
              content: text,
              font: 'big white bold center'
            }
          ],
          btns: btns
        })
      }
    },
    created () {
      const savedEggs = JSON.parse(localStorage.getItem('definitelyNotEggs'))
      if (savedEggs && savedEggs.length > 0) {
        this.retrieveEggs(savedEggs)
      }

      const visits = JSON.parse(localStorage.getItem('visits')) || 0
      this.setVisits(visits + 1)
      localStorage.setItem('visits', JSON.stringify(visits + 1))

      const savedBalls = JSON.parse(localStorage.getItem('definitelyNotBalls'))
      if (savedBalls && savedBalls.length > 0) {
        this.retrieveBalls(savedBalls)
      }

      const simon = JSON.parse(localStorage.getItem('gotSimon'))
      if (simon) {
        this.findInstrument()
      }

      const firstName = JSON.parse(localStorage.getItem('firstName'))
      if (firstName) {
        this.setName(firstName)
      }

      if (!this.eggs.free.found) {
        this.findEgg('free')
      }
      if (!this.eggs.reload.found && this.visits >= this.eggs.reload.hint.length) {
        this.findEgg('reload')
      }
    },
    watch: {
      eggsFound () {
        localStorage.setItem('definitelyNotEggs', JSON.stringify(this.eggsFound))
      },
      ballsFound () {
        localStorage.setItem('definitelyNotBalls', JSON.stringify(this.ballsFound))
      },
      foundInstrument () {
        if (this.foundInstrument) {
          localStorage.setItem('gotSimon', JSON.stringify(true))
        }
      },
      firstName () {
        if (this.firstName) {
          localStorage.setItem('firstName', JSON.stringify(this.firstName))
        }
      }
    }
  }
</script>
