<template>
  <transition name="tpin">
    <svg @click="petHim" :class="{'leprechaun--active': foundHim || irishEgg.found}" class="leprechaun" v-if="display" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="609.5px" height="524.789px" viewBox="-97.5 -12.789 609.5 524.789">
      <g class="leprechaun__body">
        <path fill="#1FD7C3" d="M157.584,443.598l23.555,34.203L157.584,512H76.69c0-33.793,40.703-68.402,40.703-68.402H157.584z"/>
        <path fill="#1FD7C3" d="M256.912,443.598l-23.551,34.203L256.912,512h80.898c0-33.789-40.707-68.402-40.707-68.402H256.912z"/>
        <path fill="#00BBC9" d="M297.104,443.598h-40.191l-23.551,34.199l9.855,14.313c29.566-7.566,54.902-15.508,76.504-24.043 C308.846,453.586,297.104,443.598,297.104,443.598z"/>
        <path fill="#00BBC9" d="M117.393,443.598c0,0-11.742,9.988-22.613,24.469c21.598,8.535,46.934,16.477,76.504,24.039l9.855-14.309 l-23.555-34.199H117.393z"/>
        <path fill="#FFF3E4" d="M157.584,443.598h99.328V512h-99.328V443.598z"/>
        <path fill="#FFCABD" d="M439.947,226.875c-0.984-5.359-6.059-8.949-11.441-8.086c-14.238,2.277-40.664,11.625-54.406,48.988 l11.621,60.398C385.721,328.176,456.889,319.277,439.947,226.875z"/>
        <path fill="#FFCABD" d="M-25.447,226.875c0.98-5.359,6.059-8.949,11.438-8.086c14.242,2.277,40.668,11.625,54.406,48.988 l-11.621,60.398C28.78,328.176-42.392,319.277-25.447,226.875z"/>
        <path fill="#FFDEBB" d="M256.912,443.598h-99.328v44.887c4.469,1.219,9.02,2.426,13.699,3.625 c11.742,3.004,23.844,4.527,35.965,4.527c12.125,0,24.226-1.523,35.968-4.527c4.676-1.195,9.227-2.406,13.695-3.625V443.598z"/>
        <path fill="#FF7F5A" d="M386.471,247.297c0-98.824-80.398-179.223-179.222-179.223c-98.824,0-179.223,80.398-179.223,179.223 c0,94.426-67.063,159.094,150.918,214.871c18.566,4.75,38.043,4.75,56.609,0C453.537,406.391,386.471,341.723,386.471,247.297z"/>
        <path fill="#FF5D29" d="M28.459,234.902h357.578c-3.004-43.754-21.781-83.238-50.676-112.801H79.135 C50.241,151.664,31.463,191.148,28.459,234.902z"/>
        <path fill="#FFDCCE" d="M209.299,98.805c-82.34-1.113-149.418,64.074-150.539,146.418 c-0.969,70.859,47.707,130.512,113.457,146.414c8.191,1.98,16.852-0.215,23.109-5.852c0.117-0.105,0.23-0.211,0.348-0.316 c6.527-5.977,16.621-5.977,23.152,0c0.113,0.105,0.227,0.211,0.344,0.313c6.27,5.652,14.949,7.832,23.148,5.844 c65.094-15.758,113.434-74.395,113.434-144.328C355.752,165.965,290.369,99.902,209.299,98.805z"/>
        <path fill="#444F5C" d="M289.225,282.277c-4.266,0-7.727-3.457-7.727-7.727v-15.938c0-4.27,3.461-7.727,7.727-7.727 s7.727,3.457,7.727,7.727v15.938C296.951,278.82,293.49,282.277,289.225,282.277z"/>
        <path fill="#FF8997" d="M300.08,305.707h-21.711c-4.266,0-7.727-3.461-7.727-7.727c0-4.27,3.461-7.727,7.727-7.727h21.711 c4.266,0,7.727,3.457,7.727,7.727C307.807,302.246,304.346,305.707,300.08,305.707z"/>
        <path fill="#444F5C" d="M125.272,282.277c-4.266,0-7.727-3.457-7.727-7.727v-15.938c0-4.27,3.461-7.727,7.727-7.727 c4.27,0,7.727,3.457,7.727,7.727v15.938C132.999,278.82,129.542,282.277,125.272,282.277z"/>
        <path fill="#FF8997" d="M136.127,305.707H114.42c-4.27,0-7.727-3.461-7.727-7.727c0-4.27,3.457-7.727,7.727-7.727h21.707 c4.27,0,7.727,3.457,7.727,7.727C143.854,302.246,140.397,305.707,136.127,305.707z"/>
        <path fill="#444F5C" d="M207.249,304.805c-7.941,0-15.406-3.328-20.477-9.129c-2.809-3.211-2.48-8.094,0.73-10.902 c3.215-2.809,8.094-2.48,10.902,0.734c2.137,2.441,5.359,3.844,8.844,3.844s6.711-1.402,8.844-3.844 c2.809-3.215,7.691-3.543,10.902-0.734c3.214,2.809,3.542,7.691,0.734,10.902C222.659,301.477,215.194,304.805,207.249,304.805z"/>
        <path fill="#FFCABD" d="M59.26,234.902h295.972c-3.914-47.418-30.109-88.512-68.094-112.801H127.081 C89.213,146.309,63.147,187.262,59.26,234.902z"/>
        <path fill="#1FD7C3" d="M355.779,0H58.717C43.647,0,31.838,12.973,33.26,27.984l7.141,75.441l166.848,16.895L374.1,103.426 l7.137-75.441C382.658,12.973,370.854,0,355.779,0z"/>
        <path fill="#00BBC9" d="M71.303,103.426l-7.137-75.441C62.745,12.973,74.549,0,89.624,0H58.717 C43.647,0,31.838,12.973,33.26,27.984l7.141,75.441l166.848,16.895l15.453-1.566L71.303,103.426z"/>
        <path fill="#4A5660" d="M374.1,103.426l-7.105,75.078H47.506l-7.105-75.078H374.1z"/>
        <path fill="#3D4851" d="M40.401,103.426l7.105,75.078h30.906l-7.109-75.078H40.401z"/>
        <path fill="#FFE76C" d="M241.002,83.641h-67.507c-9.949,0-18.012,8.066-18.012,18.012v76.852h103.531v-76.852 C259.014,91.707,250.951,83.641,241.002,83.641z"/>
        <path fill="#4A5660" d="M186.202,114.363h42.094v64.141h-42.094V114.363z"/>
        <path fill="#1FD7C3" d="M395.951,153.008H18.549c-6.332,0-11.469,5.137-11.469,11.469v28.055c0,6.332,5.133,11.469,11.469,11.469 h377.402c6.332,0,11.465-5.133,11.465-11.469v-28.055C407.416,158.141,402.283,153.008,395.951,153.008z"/>
        <path fill="#00BBC9" d="M37.987,192.531v-28.055c0-6.332,5.133-11.469,11.465-11.469H18.549c-6.332,0-11.469,5.133-11.469,11.469 v28.055c0,6.332,5.137,11.469,11.469,11.469h30.902C43.12,204,37.987,198.863,37.987,192.531z"/>
      </g>
      <g class="leprechaun__hands">
        <ellipse fill="#FFCABD" cx="-31.461" cy="512" rx="47.977" ry="49.75"/>
        <ellipse fill="#FFCABD" cx="445.961" cy="512" rx="47.977" ry="49.75"/>
      </g>
    </svg>
  </transition>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        display: false,
        delay: 1,
        foundHim: false
      }
    },
    computed: {
      ...mapState('themePicker', {
        currentTheme: state => state.current,
        themes: state => state.themes
      }),
      ...mapState({
        irishEgg: state => state.eggs.leprechaun
      })
    },
    watch: {
      currentTheme () {
        if (this.themes[this.currentTheme].sunset) {
          this.display = true
        } else {
          this.display = false
        }
      },
      irishEgg: {
        deep: true,
        handler () {
          if (!this.irishEgg.found) {
            this.foundHim = false
          }
        }
      }
    },
    methods: {
      ...mapActions({
        findEgg: 'findEgg'
      }),
      petHim () {
        if (!this.irishEgg.found) {
          this.foundHim = true
          setTimeout(() => {
            this.findEgg('leprechaun')
          }, this.delay * 1000)
        }
      }
    }
  }
</script>