<template>
  <div class="page" data-flex="justify-center items-center">
    <WAYH></WAYH>
  </div>
</template>

<script>
import WAYH from '../components/WAYH'

export default {
  name: 'Home',
  components: {
    WAYH
  }
}
</script>
