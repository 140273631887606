<template>
  <transition name="fade">
    <div v-if="foundEgg.length > 0 || needsValidation || portfolioOpened" class="overlay">
      <transition name="grow" class="overlay__inner" tag="div" mode="out-in">
        <Portfolio v-if="portfolioOpened" key="0"></Portfolio>
        <Validate v-else-if="needsValidation" key="2"></Validate>
        <EggFound v-else-if="foundEgg.length > 0" key="3"></EggFound>
      </transition>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex'
  import EggFound from './EggFound'
  import Validate from './Validate'
  import Portfolio from './Portfolio'

  export default {
    components: {
      EggFound,
      Validate,
      Portfolio
    },
    computed: {
      ...mapState({
        foundEgg: state => state.foundEgg,
        needsValidation: state => state.needsValidation,
        portfolioOpened: state => state.portfolioOpened
      })
    }
  }
</script>
